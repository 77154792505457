import { useReducer, useCallback, useMemo } from 'react';

const createInitialState = () => ({
  actionType: null,
  items: []
});

const actions = {
  INIT: 'INIT',
  RESET: 'RESET'
};

const actionTypes = {
  DELETE: 'DELETE'
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.INIT:
      return {
        actionType: action.payload.actionType,
        items: action.payload.items
      };

    case actions.RESET:
      return createInitialState();

    default:
      return state;
  }
};

const useBatchActions = ({ items, selectedItemIds }) => {
  const [state, dispatch] = useReducer(reducer, null, createInitialState);

  const itemsById = useMemo(() => {
    const result = {};

    for(const item of items) {
      result[item.id] = item;
    }

    return result;
  }, [items]);

  const init = useCallback((actionType, id) => {
    let items = [];

    if(id) {
      items.push(itemsById[id]);
    } else {
      items = selectedItemIds.map(itemId => itemsById[itemId]);
    }

    dispatch({
      type: actions.INIT,
      payload: {
        actionType,
        items
      }
    });
  }, [itemsById, selectedItemIds]);

  const deleteItems = useCallback(id => {
    init(actionTypes.DELETE, id);
  }, [init]);

  const reset = useCallback(() => {
    dispatch({ type: actions.RESET });
  }, []);

  return {
    state,
    actionTypes,
    // methods
    deleteItems,
    reset
  };
};

export default useBatchActions;
