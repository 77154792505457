import styled from 'styled-components';

import Box from '@mui/material/Box';

const Container = styled(Box)`
  margin: 0 24px;
  border: 1px solid var(--Light-Divider, #E1E4E7);
  border-radius: 5px;
  padding: 8px 16px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .iframe-container {
    position: relative;
    flex: 1 1 100%;

    iframe {
      border: none;
      width: 100%;
    }
  }
`;

export default Container;
