import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';

import { Container, TopBlock } from './styles';

const ItemTitle = ({ title }) => {
  return (
    <Container>
      <TopBlock>
        <Typography
          fontSize="14px"
        >
          {title}
        </Typography>

      </TopBlock>
    </Container>
  );
};

ItemTitle.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ItemTitle;
