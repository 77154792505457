import React, { useCallback } from 'react';
import PT from 'prop-types';

import List from '@mui/material/List';

import SingleColumn from './SingleColumn';

const ColumnsList = ({
  onSelectColumn,
  columns,
  focusIndex = 0
}) => {
  const handleSelect = useCallback((e, id, title) => {
    e.preventDefault();
    onSelectColumn({ id, title });
  }, [onSelectColumn]);

  return (
    <List
      role="listbox"
      aria-label="columns suggestions"
      dense
      sx={{
        width: '300px'
      }}
    >
      {columns?.map(({ id, title, fullTitle }, i) => (
        <SingleColumn
          key={id}
          id={id}
          title={title}
          fullTitle={fullTitle}
          onSelect={handleSelect}
          selected={focusIndex === i}
        />
      ))}
    </List>
  );
};

ColumnsList.propTypes = {
  onSelectColumn: PT.func.isRequired,
  columns: PT.object,
  focusIndex: PT.number
};

export default ColumnsList;
