import React from 'react';
import { useSlateStatic } from 'slate-react';
import PT from 'prop-types';

const RenderedElement = (props) => {
  const editor = useSlateStatic();

  if(props.element.type === 'formula') {
    return (
      <div {...props.attributes}>{props.children}</div>
    );
  }

  if(props.element.type === 'column') {
    return (
      <div
        {...props.attributes}
        contentEditable={false}
        style={{
          display: 'inline-block'
        }}
      >
        {props.children}{props.element.children[0].text}
      </div>
    );
  }

  const Tag = editor.isInline(props.element) ? 'span' : 'div';

  return (
    <Tag {...props.attributes}>{props.children}</Tag>
  );
};

RenderedElement.propTypes = {
  element: PT.object.isRequired,
  attributes: PT.object.isRequired,
  children: PT.object.isRequired,
};

export default RenderedElement;
