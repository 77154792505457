import { gql } from '@apollo/client';

export const FORMULAS = gql`
  query MathOperations {
    mathOperations {
      title
      functionName
      description
    }
  }
`;

