import styled from 'styled-components';
import {
  MEDIA_QUERIES_XS,
  LG
} from '../../styles';

export default styled.div.attrs({ className: 'filter-bar' })`
  display: flex;
  margin: 0px 0 15px;
  align-items: center;

  .MuiInputBase-root {
    flex-grow: 1;
    height: 40px;
    box-sizing: border-box;
    max-width: 400px;
    display: flex;
  }
  
  .search-input {
    fieldset {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      &:hover {      
       border-color: rgba(0, 0, 0, 0.23); 
      } 
    }
    input {
      padding-left: 14px;
    }
    :hover {      
      fieldset {      
        border-color: #00bcd4 !important; 
      }
    }
  }
 
  .search-button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: none;
    min-width: 40px;
  }

  .search-types-panel {
    margin-right: 20px;
  }

  .actions {
    display: flex;
  }

  @media (max-width: ${LG}px) {
    flex-direction: column;
    align-items: flex-start;

    .search-types-panel {
      margin-right: 0;
      margin-bottom: 20px;
    }

    .search-container {
      width: 100%;
      max-width: 500px;
    }
  }

  @media (${MEDIA_QUERIES_XS}) {
    margin: 0 8px 15px;
  }

`;
