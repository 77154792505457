import React, { memo, useCallback, useRef } from 'react';
import PT from 'prop-types';

import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import EmptyValue from './EmptyValue';

import { hasWriteAccess } from '../../../../utils/roles';

import {
  BooleanValue,
  LinkValue,
  QuantityValue,
  TextValue,
  CalculationValue
} from './Values';
import { ROW_HEIGHT_PX } from '../../constants';
import { VALUE_TYPE } from '../../../../services/samples/constants';

const getValueTag = (type) => {
  switch (type) {
    case VALUE_TYPE.QUANTITY:
      return QuantityValue;

    case VALUE_TYPE.BOOLEAN:
      return BooleanValue;

    case VALUE_TYPE.TEXT:
      return TextValue;

    case VALUE_TYPE.LINK:
      return LinkValue;

    case VALUE_TYPE.CALCULATION:
      return CalculationValue;

    default:
      throw new Error('Unhandled type');
  }
};

const useStyles = makeStyles({
  root: {
    height: `${ROW_HEIGHT_PX}px`,
    border: '1px solid transparent',

    '&:hover': {
      border: ({ canWrite }) => canWrite ? '1px solid #D9D9D9' : 'auto'
    }
  }
});

const ParameterValueContent = ({ getValue, row, ...props }) => {
  const { data: { table, id } } = row;

  const canWrite = hasWriteAccess(table.viewerMaxRole);

  const classes = useStyles({ canWrite });
  const featureValue = getValue();
  const containerRef = useRef();

  const handleEditModeChange = useCallback(mode => {
    if (!containerRef.current) return;

    if (mode) {
      containerRef.current.style.width = getComputedStyle(containerRef.current)?.width;
    } else {
      containerRef.current.style.width = 'auto';
    }
  }, []);

  if(!featureValue)
    return <EmptyValue />;

  const ValueTag = getValueTag(featureValue.type);

  return (
    <Box
      display="flex"
      alignItems="center"
      className={classes.root}
      ref={containerRef}
    >
      <ValueTag
        data={featureValue}
        tableParameterId={getValue().tableParameterId}
        itemId={id}
        readOnly={!canWrite}
        onEditModeChange={handleEditModeChange}

        {...props}
      />
    </Box>
  );
};

ParameterValueContent.propTypes = {
  getValue: PT.func,
  row: PT.shape({
    id: PT.string.isRequired,
    data: PT.shape({
      id: PT.string.isRequired,
      table: PT.shape({
        viewerMaxRole: PT.string
      }).isRequired,
    })
  })
};

export default memo(ParameterValueContent);
