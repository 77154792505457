import React, { useCallback, useEffect, useRef } from 'react';
import PT from 'prop-types';

import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

const SingleColumn = ({ id, title, fullTitle, selected, onSelect }) => {

  const ref = useRef(null);

  const handleSelect = useCallback((e) => {
    onSelect(e, id, title);
  }, [id, onSelect, title]);

  useEffect(() => {
    if(selected)
      ref.current.scrollIntoView(false);
  }, [selected]);

  return (
    <ListItemButton
      key={id}
      selected={selected}
      onClick={handleSelect}
      ref={ref}
    >
      <ListItemText>{fullTitle}</ListItemText>
    </ListItemButton>
  );
};

SingleColumn.propTypes = {
  id: PT.string.isRequired,
  title: PT.string.isRequired,
  fullTitle: PT.string.isRequired,
  selected: PT.bool.isRequired,
  onSelect: PT.func.isRequired,
};

export default SingleColumn;
