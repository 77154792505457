import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { Container } from './styles';
import CalculationIcon from '../../../../../Icons/CalculationIcon';

import { parseToExponential } from '../../../../../../utils';

const CalculatedValue = ({ data }) => {
  const displayValue = useMemo(() => {
    const value = typeof data.quantity === 'number' ?
      parseToExponential(data.quantity) :
      data.calculationError ? '#ERROR' : '';

    const tooltip = data.quantity ?? data.calculationError;

    return { value, tooltip };
  }, [data.quantity, data.calculationError]);

  return (
    <>
      <Container>
        <Tooltip title={displayValue.tooltip}>
          <Typography
            sx={{
              fontSize: '14px'
            }}
          >
            {displayValue.value}
          </Typography>
        </Tooltip>

        {typeof data.quantity === 'number' ?
          <Typography
            sx={{
              fontSize: '14px'
            }}
          >
            {data.unit?.name}
          </Typography> :
          null
        }
      </Container>

      <Tooltip
        title={data.calculationFormula}
        placement="top"
        arrow
      >
        <CalculationIcon
          type={data.calculationError ? 'ERROR_BADGE' : 'NORMAL'}
          sx={{
            fontSize: '14px',
            color: 'rgba(0,0,0,.6)'
          }}
        />
      </Tooltip>
    </>
  );
};

CalculatedValue.propTypes = {
  data: PropTypes.object.isRequired
};

export default CalculatedValue;
