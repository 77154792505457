import styled from 'styled-components';

const EditableWrapper = styled.div`
  font-size: 14px;
  border-radius: 4px 4px 0 0;
  border: 1px solid #DCDBDC;
  padding: 10px;

  > *:first-child {
    min-height: 80px !important;
  }
`;

export default EditableWrapper;
