import React from 'react';
import PT from 'prop-types';

import Typography from '@mui/material/Typography';

import UnitsSelect from './UnitsSelect';
import FormulationSelect from './FormulationSelect';

const ContentItemValue = ({ parameterId, columns, data, disabled, onChange }) => {
  switch(data.type) {
    case 'TEXT':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can enter free text
        </Typography>
      );

    case 'BOOLEAN':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can select "Yes" or "No"
        </Typography>
      );

    case 'LINK':
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          User can select any link to item
        </Typography>
      );

    case 'QUANTITY':
      return (
        <UnitsSelect
          value={data.value?.unit}
          disabled={disabled}
          onChange={onChange}
        />
      );

    case 'CALCULATION':
      return (
        <FormulationSelect
          parameterId={parameterId}
          columns={columns}
          value={data.value}
          disabled={disabled}
          onChange={onChange}
        />
      );

    default:
      return (
        <Typography
          fontSize="12px"
          lineHeight="28px"
          color="#9E9E9E"
        >
          Unknown type
        </Typography>
      );
  }
};

ContentItemValue.propTypes = {
  parameterId: PT.string.isRequired,
  columns: PT.array.isRequired,
  data: PT.shape({
    type: PT.oneOf(['TEXT', 'BOOLEAN', 'QUANTITY', 'LINK', 'CALCULATION']),
    value: PT.oneOfType([
      // units for the 'QUANTITY' type
      PT.shape({
        unit: PT.object,
        calculation: PT.object,
        description: PT.string
      })
    ])
  }),
  disabled: PT.bool,
  onChange: PT.func
};

export default ContentItemValue;
