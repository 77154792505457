import React, { useCallback, useState, useMemo, useReducer, useEffect } from 'react';
import { useMutation, NetworkStatus, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import difference from 'lodash/difference';
import sumBy from 'lodash/sumBy';
import isArray from 'lodash/isArray';

import Page from '../../components/Page';
import PageTitle from '../../components/PageTitle';
import SamplesTable from '../../components/SamplesTable';
import FilterBar from '../../components/FilterBar';
import EnabledFiltersList from '../../components/EnabledFiltersList';

import ActionsPanel from './ActionsPanel';
import ItemActions from './ItemActions';

import { TRASH_SORT_SETTINGS } from '../../services/samples/constants';
import { RESTORE } from '../../services/samples/gql';
import { FETCH_TRASH } from './services/gql';

import useItemsList from '../../hooks/items-list';
import { browserStorage } from '../../utils';
import { itemsResetAndDeletion } from '../../services/samples/utils';
import { actions, initState, reducer } from '../../states/checkedItems/reducer';

import { PageContent } from './styles';

import useDocumentTitle from '../../hooks/document-title';
import { openAppSnackbarNotification } from '../../services/snackbar-notifications/actions';

const columnsMap = [
  {
    value: 'sample-name',
    label: 'Name',
    expandAction: true,
    sortProp: 'title',
    sortable: true,
    component: true,
  },
  {
    value: 'trashedTimestamp',
    label: 'Deleted',
    sortProp: 'trashedTimestamp',
    sortable: true,
  },
  {
    value: 'type',
    label: 'Type',
    sortProp: 'type',
    sortable: true,
  },
  {
    value: 'path',
    label: 'Location',
    sortProp: 'path',
    sortable: true,
  },
  {
    value: 'creator',
    label: 'Creator',
    component: true,
    sortProp: 'creator',
    sortable: true,
  },
  { value: 'action', label: '', component: true },
];

const DeletedItems = () => {

  useDocumentTitle('Trash');

  const [sortSettings, setSortSettings] = useState(
    browserStorage.get(TRASH_SORT_SETTINGS) || {}
  );
  const [pageRefElement, setPageRefElement] = useState(null);
  const [search, setSearch] = useState('');
  const [
    isCheckedItemsFilterActive,
    setIsCheckedItemsFilterActive
  ] = useState(false);

  const [total, setTotal] = useState(0);
  const [totalFiltered, setTotalFiltered] = useState(0);

  const [disabled, setDisabled] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);

  const dispatchGlobal = useDispatch();

  const itemsById = state.itemsById;
  const isCheckedAll = state.selectAll;

  const {
    data,
    loading,
    updateQuery,
    networkStatus
  } = useQuery(FETCH_TRASH, { fetchPolicy: 'cache-and-network' });

  const [restore, {
    loading: isRestoring,
  }] = useMutation(RESTORE);

  const {
    mappedSamples,
    filteredListCount,
  } = useItemsList(data, {
    search,
    sortSettings,
    isCheckedItemsFilterActive,
    itemsById
  });

  useEffect(() => {
    const itemsToAdd = difference(mappedSamples.map(sample => sample.id), Object.keys(itemsById));

    if (itemsToAdd.length){
      dispatch({
        type: actions.ADD_ITEMS,
        payload: mappedSamples
          .map(sample => ({ id: sample.id, type: sample.type }))
          .filter(id => !Object.keys(itemsById).includes(id))
      });
    }
  }, [mappedSamples, itemsById]);

  useEffect(() => {
    const totalNumberOfItems = data?.trash ? sumBy(Object.values(data?.trash).filter(isArray), value => value.length) : 0;

    setTotal(totalNumberOfItems);
  }, [data?.trash]);

  const onItemsListReset = useCallback((ids) => {
    dispatch({
      type: actions.REMOVE_ITEMS,
      payload: ids
    });
  }, []);

  const handleRestoreItem = useCallback(async (item) => {
    try {
      const variables = {};
      if (item.type === 'Folder') {
        variables.folderIds = [item.id];
      } else if(item.type === 'Table') {
        variables.tableIds = [item.id];
      } else {
        variables.tableItemIds = [item.id];
      }

      await restore({
        variables
      });

      setTotal(s => s - 1);

      if (search) {
        setTotalFiltered(s => s - 1);
      }

      const resetProps = { updateQuery, onItemsListReset };
      if (item.type === 'Folder') {
        resetProps.folderIdsToDelete = [item.id];
      } else if(item.type === 'Table') {
        resetProps.tableIdsToDelete = [item.id];
      } else {
        resetProps.itemIdsToDelete = [item.id];
      }

      itemsResetAndDeletion(resetProps);
    } catch (e) {
      console.error(e);

      dispatchGlobal(
        openAppSnackbarNotification({
          message: e.message,
          variant: 'ERROR'
        })
      );
    }
  }, [dispatchGlobal, onItemsListReset, restore, search, updateQuery]);

  const handleSearchTextChange = useCallback(text => {
    setSearch(text);
  }, []);

  const handleRestoreAction = useCallback(async () => {
    setDisabled(true);
    const ids = Object.keys(itemsById).filter((id) => itemsById[id].checked);

    try {
      const variables = Object.keys(itemsById)
        .filter((id) => itemsById[id].checked)
        .reduce((prev, id) => {
          const result = prev;

          if (itemsById[id].type === 'Folder') {
            result.folderIds = [...(prev.folderIds || []), id];
          } else if (itemsById[id].type === 'Table') {
            result.tableIds = [...(prev.tableIds || []), id];
          } else {
            result.tableItemIds = [...(prev.tableItemIds || []), id];
          }

          return result;
        }, {});

      await restore({
        variables
      });

      setTotal(s => s - ids.length);

      if (search) {
        setTotalFiltered(s => s - ids.length);
      }

      itemsResetAndDeletion({
        updateQuery,
        onItemsListReset,
        itemIdsToDelete: variables.tableItemIds,
        tableIdsToDelete: variables.tableIds,
        folderIdsToDelete: variables.folderIds
      });
    } catch (e) {
      console.error(e);

      dispatchGlobal(
        openAppSnackbarNotification({
          message: e.message,
          variant: 'ERROR'
        })
      );
    }

    setDisabled(false);
  }, [itemsById, onItemsListReset, restore, search, updateQuery, dispatchGlobal]);

  const handleCheck = useCallback((sampleId, checked) => {
    dispatch({
      type: actions.CHECK_ITEM,
      payload: {
        id: sampleId,
        checked
      }
    });
  }, []);

  const handleCheckAll = useCallback((checked) => {
    dispatch({
      type: actions.CHECK_ALL_ITEMS,
      payload: {
        checked
      }
    });
  }, []);

  const renderOptionColumn = useCallback(item => (
    <ItemActions
      item={item}
      onRestore={handleRestoreItem.bind(null, item)}
      disableDelete={item.isUsedInProtocols || !item.archived}
    />
  ), [handleRestoreItem]);

  const handleSort = useCallback(sortSettings => {
    setSortSettings(sortSettings);
  }, []);

  const handleFilterSearchRemove = useCallback(() => {
    handleSearchTextChange('');
  }, [handleSearchTextChange]);

  const handleFilterSelectedItemsRemove = useCallback(() => {
    setIsCheckedItemsFilterActive(false);
  }, [setIsCheckedItemsFilterActive]);

  const handleSelectedItemsChipClick = useCallback(() => {
    setIsCheckedItemsFilterActive(true);
  }, [setIsCheckedItemsFilterActive]);

  const isAnyItemSelected = useMemo(() => {
    return Object.keys(itemsById)
      .some(id => itemsById[id].checked);
  }, [itemsById]);

  return (
    <Page ref={setPageRefElement}>
      <PageContent loading={networkStatus === NetworkStatus.loading}>
        <PageTitle>Trash</PageTitle>

        <FilterBar
          isLoading={loading}
          value={search}
          onTextChange={handleSearchTextChange}
        />

        <ActionsPanel
          onRestore={handleRestoreAction}
          isItemsRestoring={isRestoring}
          onSelectedItemsChipClick={handleSelectedItemsChipClick}
          totalNumberOfItems={total}
          totalNumberOfFilteredItems={totalFiltered}
          isSearchTextExist={Boolean(search)}
          itemsById={itemsById}
        />

        <EnabledFiltersList
          isSearchTextExist={Boolean(search)}
          isAnyItemSelected={isAnyItemSelected}
          isSelectedIdsActive={isCheckedItemsFilterActive}
          onSearchRemove={handleFilterSearchRemove}
          onSelectedItemsRemove={handleFilterSelectedItemsRemove}
        />

        <SamplesTable
          archivePage
          totalListCount={filteredListCount}
          sortSettings={sortSettings}
          list={mappedSamples}
          loading={loading}
          disabled={disabled}
          onSort={handleSort}
          columnsMap={columnsMap}
          renderItemActions={renderOptionColumn}
          scrollElement={pageRefElement}
          onCheckAll={handleCheckAll}
          onCheck={handleCheck}
          itemsById={itemsById}
          isCheckedAll={isCheckedAll}
        />
      </PageContent>
    </Page>
  );
};

export default DeletedItems;
