import { useReducer, useCallback } from 'react';

import without from 'lodash/without';
import omit from 'lodash/omit';

const mapInitialParameters = (config) => {
  const parameters = {
    ids: [],
    byId: {},
    errorById: {}
  };

  if(!config) {
    const id = window.crypto.randomUUID();

    parameters.ids = [id];
    parameters.byId = {
      [id]: {
        title: {
          type: 'TEXT',
          value: null
        },
        value: {
          type: 'QUANTITY',
          value: null
        }
      }
    };

    return parameters;
  }

  for(const parameter of config.tableParameters) {
    const { id, title, titleTableItem, valueType, unit, description, calculation } = parameter;

    const value = {
      unit: unit ? { id: unit.id, name: unit.name } : null,
      calculation: calculation?.contents,
      description
    };

    parameters.ids.push(id);
    parameters.byId[id] = {
      id,
      title: {
        type: titleTableItem ? 'LINK' : 'TEXT',
        value: titleTableItem ?
          { code: titleTableItem.code, label: titleTableItem.title, value: titleTableItem.id } :
          title
      },
      value: {
        type: valueType,
        value
      }
    };
  }

  return parameters;
};

const createInitialState = (config) => {
  return {
    title: {
      value: config?.title ?? '',
      error: null
    },
    description: {
      value: config?.description ?? '',
      error: null
    },
    parameters: mapInitialParameters(config)
  };
};

const actions = {
  SET_TITLE_VALUE: 'SET_TITLE_VALUE',
  SET_TITLE_ERROR: 'SET_TITLE_ERROR',
  SET_DESCRIPTION_VALUE: 'SET_DESCRIPTION_VALUE',
  ADD_PARAMETER: 'ADD_PARAMETER',
  REMOVE_PARAMETER: 'REMOVE_PARAMETER',
  SET_PARAMETER_TITLE_TYPE: 'SET_PARAMETER_TITLE_TYPE',
  SET_PARAMETER_TITLE: 'SET_PARAMETER_TITLE',
  SET_PARAMETER_VALUE_TYPE: 'SET_PARAMETER_VALUE_TYPE',
  SET_PARAMETER_VALUE: 'SET_PARAMETER_VALUE',
  SET_PARAMETER_ORDER: 'SET_PARAMETER_ORDER',
  SET_PARAMETER_ERROR: 'SET_PARAMETER_ERROR',
  RESET: 'RESET'
};

const addParameterReducer = (state) => {
  const id = window.crypto.randomUUID();

  return {
    ...state,
    parameters: {
      ...state.parameters,
      ids: state.parameters.ids.concat(id),
      byId: {
        ...state.parameters.byId,
        [id]: {
          title: {
            type: 'TEXT',
            value: null
          },
          value: {
            type: 'QUANTITY',
            value: null
          }
        }
      }
    }
  };
};

const removeParameterReducer = (state, action) => {
  return {
    ...state,
    parameters: {
      ...state.parameters,
      ids: without(state.parameters.ids, action.payload),
      byId: omit(state.parameters.byId, action.payload),
      errorById: omit(state.parameters.errorById, action.payload)
    }
  };
};

const setParameterTitleTypeReducer = (state, action) => {
  const { id, type } = action.payload;

  return {
    ...state,
    parameters: {
      ...state.parameters,
      byId: {
        ...state.parameters.byId,
        [id]: {
          ...state.parameters.byId[id],
          title: {
            type,
            value: null
          }
        }
      },
      errorById: omit(state.parameters.errorById, action.payload.id)
    }
  };
};

const setParameterTitleReducer = (state, action) => {
  const { id, value } = action.payload;

  return {
    ...state,
    parameters: {
      ...state.parameters,
      byId: {
        ...state.parameters.byId,
        [id]: {
          ...state.parameters.byId[id],
          title: {
            ...state.parameters.byId[id].title,
            value
          }
        }
      },
      errorById: omit(state.parameters.errorById, action.payload.id)
    }
  };
};

const setParameterValueTypeReducer = (state, action) => {
  const { id, type } = action.payload;

  return {
    ...state,
    parameters: {
      ...state.parameters,
      byId: {
        ...state.parameters.byId,
        [id]: {
          ...state.parameters.byId[id],
          value: {
            type,
            value: null
          }
        }
      },
      errorById: omit(state.parameters.errorById, action.payload.id)
    }
  };
};

const setParameterValueReducer = (state, action) => {
  const { id, value } = action.payload;

  return {
    ...state,
    parameters: {
      ...state.parameters,
      byId: {
        ...state.parameters.byId,
        [id]: {
          ...state.parameters.byId[id],
          value: {
            ...state.parameters.byId[id].value,
            value
          }
        }
      },
      errorById: omit(state.parameters.errorById, action.payload.id)
    }
  };
};

const setParameterOrderReducer = (state, action) => {
  const { id, index } = action.payload;
  const withoutParameter = without(state.parameters.ids, id);

  return {
    ...state,
    parameters: {
      ...state.parameters,
      ids: [
        ...withoutParameter.slice(0, index),
        id,
        ...withoutParameter.slice(index)
      ]
    }
  };
};

const setParameterErrorReducer = (state, action) => {
  const { id, message } = action.payload;

  return {
    ...state,
    parameters: {
      ...state.parameters,
      errorById: {
        ...state.parameters.errorById,
        [id]: {
          message
        }
      }
    }
  };
};

const reducer = (state, action) => {
  switch(action.type) {
    case actions.SET_TITLE_VALUE:
      return {
        ...state,
        title: {
          ...state.title,
          error: null,
          value: action.payload
        }
      };

    case actions.SET_TITLE_ERROR:
      return {
        ...state,
        title: {
          ...state.title,
          error: action.payload,
        }
      };

    case actions.SET_DESCRIPTION_VALUE:
      return {
        ...state,
        description: {
          ...state.description,
          error: null,
          value: action.payload
        }
      };

    case actions.ADD_PARAMETER:
      return addParameterReducer(state, action);

    case actions.REMOVE_PARAMETER:
      return removeParameterReducer(state, action);

    case actions.SET_PARAMETER_TITLE_TYPE:
      return setParameterTitleTypeReducer(state, action);

    case actions.SET_PARAMETER_TITLE:
      return setParameterTitleReducer(state, action);

    case actions.SET_PARAMETER_VALUE_TYPE:
      return setParameterValueTypeReducer(state, action);

    case actions.SET_PARAMETER_VALUE:
      return setParameterValueReducer(state, action);

    case actions.SET_PARAMETER_ORDER:
      return setParameterOrderReducer(state, action);

    case actions.SET_PARAMETER_ERROR:
      return setParameterErrorReducer(state, action);

    case actions.RESET:
      return createInitialState(action.payload);

    default:
      return state;
  }
};

const useTableProtocolForm = (config) => {
  const [state, dispatch] = useReducer(reducer, config, createInitialState);

  const setTitleValue = useCallback(value => {
    dispatch({
      type: actions.SET_TITLE_VALUE,
      payload: value
    });
  }, []);

  const setTitleError = useCallback(value => {
    dispatch({
      type: actions.SET_TITLE_ERROR,
      payload: value
    });
  }, []);

  const setDescriptionValue = useCallback(value => {
    dispatch({
      type: actions.SET_DESCRIPTION_VALUE,
      payload: value
    });
  }, []);

  const addParameter = useCallback(() => {
    dispatch({ type: actions.ADD_PARAMETER });
  }, []);

  const removeParameter = useCallback((id) => {
    dispatch({ type: actions.REMOVE_PARAMETER, payload: id });
  }, []);

  const setParameterTitleType = useCallback((id, type) => {
    dispatch({
      type: actions.SET_PARAMETER_TITLE_TYPE,
      payload: { id, type }
    });
  }, []);

  const setParameterTitle = useCallback((id, value) => {
    dispatch({
      type: actions.SET_PARAMETER_TITLE,
      payload: { id, value }
    });
  }, []);

  const setParameterValueType = useCallback((id, type) => {
    dispatch({
      type: actions.SET_PARAMETER_VALUE_TYPE,
      payload: { id, type }
    });
  }, []);

  const setParameterValue = useCallback((id, value) => {
    dispatch({
      type: actions.SET_PARAMETER_VALUE,
      payload: { id, value }
    });
  }, []);

  const setParameterOrder = useCallback((id, index) => {
    dispatch({
      type: actions.SET_PARAMETER_ORDER,
      payload: { id, index }
    });
  }, []);

  const setParameterError = useCallback((id, message) => {
    dispatch({
      type: actions.SET_PARAMETER_ERROR,
      payload: { id, message }
    });
  }, []);

  const resetState = useCallback(() => {
    dispatch({ type: actions.RESET, payload: config });
  }, [config]);

  return {
    state,
    setTitleValue,
    setTitleError,
    setDescriptionValue,
    addParameter,
    removeParameter,
    setParameterTitleType,
    setParameterTitle,
    setParameterValueType,
    setParameterValue,
    setParameterOrder,
    setParameterError,
    resetState
  };
};

export default useTableProtocolForm;
