import React, { forwardRef } from 'react';
import PT from 'prop-types';

import CalculationIconNormal from './CalculationIconNormal';
import CalculationIconError from './CalculationIconError';
import CalculationIconErrorBadge from './CalculationIconErrorBadge';

const CalculationIcon = forwardRef(({ type, ...props }, ref) => {
  switch(type) {
    case 'ERROR':
      return (
        <CalculationIconError {...props} ref={ref} />
      );

    case 'ERROR_BADGE':
      return (
        <CalculationIconErrorBadge {...props} ref={ref} />
      );

    case 'NORMAL':
    default:
      return (
        <CalculationIconNormal {...props} ref={ref} />
      );
  }
});

CalculationIcon.propTypes = {
  type: PT.oneOf(['NORMAL', 'ERROR', 'ERROR_BADGE'])
};

export default CalculationIcon;

