import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Divider from '@mui/material/Divider';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import SingleFeature from '../SingleFeature';

import { ReactComponent as InfoIcon } from '../../../images/info.svg';

import { IconStyled, WrapperStyled } from './styles';

import { getTooltipText } from './utils';

const SingleProtocol = ({
  id,
  data,
  showEmptyValues,
  isOpen,
  onClose,
  readOnly
}) => {
  const filteredData = useMemo(() => {
    if (!showEmptyValues && data){
      return data.features && data.features
        .filter(object => object.link || typeof object.quantity === 'number'
            || object.text || typeof object.boolean === 'boolean'
        );
    }

    return data?.features;
  }, [data, showEmptyValues]);

  const handleClose = useCallback(() => {
    if (filteredData?.length)
      onClose(data.id);
  }, [data.id, filteredData?.length, onClose]);

  return (
    <WrapperStyled
      isopen={isOpen ? 'true' : undefined}
      active={filteredData?.length}
      className="singleProtocol"
    >
      <ListItemButton
        onClick={handleClose}
        disableRipple={!filteredData?.length}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '6px'
          }}
        >
          <ListItemText
            sx={{
              '.MuiTypography-root': {
                fontSize: 14,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '400px'
              }
            }}
          >
            {data?.title}
          </ListItemText>

          <IconStyled>
            <Tooltip
              title={
                <Box sx={{ whiteSpace: 'pre-line' }}>
                  {getTooltipText(data)}
                </Box>
              }
            >
              <InfoIcon />
            </Tooltip>
          </IconStyled>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px'
          }}
        >
          {filteredData?.length ?
            <KeyboardArrowDownIcon
              sx={{
                transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                fontSize: '16px'
              }}
            />
            : null
          }
        </Box>
      </ListItemButton>

      <Collapse
        in={isOpen}
      >
        <Box
          sx={{
            padding: '8px'
          }}
        >
          <Table
            sx={{
              display: 'block'
            }}
          >
            <TableBody
              sx={{
                display: 'block',
                maxWidth: '100%',
                '.MuiTableRow-root:last-child td': {
                  borderBottom: 0
                },
              }}
            >
              {filteredData?.map(feature => (
                <SingleFeature
                  key={feature.id}
                  itemId={id}
                  data={feature}
                  readOnly={readOnly || feature.valueType === 'CALCULATION'}
                />
              ))}
            </TableBody>
          </Table>
        </Box>
      </Collapse>

      <Divider />
    </WrapperStyled>
  );
};

SingleProtocol.propTypes = {
  id: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  showEmptyValues: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default SingleProtocol;

