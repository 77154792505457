import { gql } from '@apollo/client';

const CHILD_FRAGMENT = gql`
  fragment ChildrenFields on CalculationContentChild {
    id
    type
    text
  }
`;

export const TABLE_SETTINGS = gql`
  query TableSettings($id: ID!) {
    table(id: $id) {
      id
      title
      description
      numberOfTableItems
      path {
        title
      }
      dateUpdated
      viewerMaxRole
      hash
      iam {
        organization
        users {
          userId
          name
          firstName
          lastName
          avatarColor
          pictureDownloadURL
          role
          dateJoined
        }
      }
      tableProtocols {
        hash
        id
        title
        description
        creator {
          id
          name
        }
        dateCreated
        dateUpdated
        tableParameters {
          id
          title
          titleTableItem {
            id
            title
            code
          }
          valueType
          unit {
            id
            name
          }
          description
          calculation {
            contents {
              type
              children {
                ...ChildrenFields
                children {
                  ...ChildrenFields
                }
              }
            }
          }
        }
      }
    }
  }
  ${CHILD_FRAGMENT}
`;
