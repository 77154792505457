import { useQuery } from '@apollo/client';

import { GET_OPTIONS } from './gql';

const useFilterOptions = ({ tableIds }) => {
  const filtersOptions = useQuery(GET_OPTIONS, {
    variables: { tableIds },
    skip: !tableIds?.length,
    fetchPolicy: 'cache-and-network'
  });

  return {
    data: filtersOptions.data?.tableItemsFilterOptions,
    loading: filtersOptions.loading,
    refetch: filtersOptions.refetch,
  };
};

export default useFilterOptions;

