import React, { useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';

import AllItems from './AllItems';
import LoadingBanner from '../../components/LoadingBanner';

import { GET_FOLDERS, GET_TABLES } from './services';

import { serializeCalculation } from '../../utils';
import { COLOR_PRIMARY, COLOR_WHITE } from '../../styles';

const AllItemsContainer = () => {
  const loaderData = useLoaderData();

  const { data: foldersData } = useQuery(GET_FOLDERS);
  const { data: tablesData } = useQuery(GET_TABLES);

  const mappedTables = useMemo(() => {
    const result = { tables: [], parametersById: {} };

    for(const table of tablesData?.tables ?? []) {
      const _table = { ...table, tableParameters: [] };

      for(const param of table.tableParameters) {
        _table.tableParameters.push(param.featureId);
        result.parametersById[param.id] = { ...param };

        if(param.calculation) {
          result.parametersById[param.id].calculationFormula = serializeCalculation(param.calculation.contents);
        }
      }

      result.tables.push(_table);
    }

    return result;
  }, [tablesData?.tables]);

  const mergedLoaderData = useMemo(() => ({
    ...loaderData,
    tablesData: mappedTables.tables,
    foldersData: foldersData?.folders,
    parametersById: mappedTables.parametersById
  }), [foldersData?.folders, loaderData, mappedTables]);

  if (!foldersData || !tablesData)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
        }}
      >
        <LoadingBanner
          spinner
          bcgcolor={COLOR_WHITE}
          color={COLOR_PRIMARY}
        />
      </Box>
    );

  return <AllItems loaderData={mergedLoaderData} />;
};

export default AllItemsContainer;
