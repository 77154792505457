import { gql } from '@apollo/client';
import { GET_NOTIFICATIONS_FRAGMENT } from '../../../../../components/Notifications/gql';

export const GET_NOTIFICATIONS = gql`
  ${GET_NOTIFICATIONS_FRAGMENT}
  query Notifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      edges {
        ...NotificationListEdge
      }
    }
  }
`;
