import React from 'react';
import PT from 'prop-types';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const SelectedTablesTitle = ({ data, totalItems }) => {
  if(!data.length)
    return null;

  if(data.length === 1)
    return (
      <Tooltip
        title={data[0].path + data[0].title}
        placement="bottom-start"
        arrow
      >
        <Typography fontSize={18} fontWeight="bold">
          {data[0].title} {typeof totalItems === 'number' ? `(${totalItems})` : null}
        </Typography>
      </Tooltip>
    );

  return (
    <Tooltip
      title={
        data.map(({ id, path, title }) => (
          <Typography key={id} fontSize="12px">{path + title}</Typography>
        ))
      }
      placement="bottom-start"
      arrow
    >
      <Typography fontSize={18} fontWeight="bold">
        {data.length} tables {typeof totalItems === 'number' ? `(${totalItems})` : null}
      </Typography>
    </Tooltip>
  );
};

SelectedTablesTitle.propTypes = {
  data: PT.arrayOf(PT.shape({
    id: PT.string,
    title: PT.string,
    path: PT.string
  })),
  totalItems: PT.number
};

export default SelectedTablesTitle;

