import styled from 'styled-components';
import { PageContent as PageContentLayout } from '../../components/Page';

const CHECKBOX_COLUMN_WIDTH = 30;
const DATA_COLUMN_WIDTH = 140;
const OPTION_COLUMN_WIDTH = 40;
const ALL_FIXED_COLUMNS_WIDTH_SUM =
  CHECKBOX_COLUMN_WIDTH +
  DATA_COLUMN_WIDTH * 4 +
  OPTION_COLUMN_WIDTH;

export const PageContent = styled(PageContentLayout)
  .attrs({ className: 'deleted-items-content' })`
    .table-container {
      .table-cell {
        &.sample-name {
          width: calc(100% - ${ALL_FIXED_COLUMNS_WIDTH_SUM}px);
        }
      }
    }
  `;
