import React, { useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useAnalytics } from 'use-analytics';
import PT from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Chip from '@mui/material/Chip';

import FormulaEditor from './FormulaEditor';
import UnitsBlock from './UnitsBlock';

import useCalculationForm from './reducer';

import { FORMULAS } from './gql';
import CalculationIcon from '../../../../../../../../../components/Icons/CalculationIcon';
import { COLOR_GREY_LIGHTER } from '../../../../../../../../../styles';

import {
  componentNames,
  TRACK_CALCULATED_FIELDS,
} from '../../../../../../../../../analytics/constants';

const styles = {
  dialog: {
    maxHeight: '560px'
  },
  formControl: {
    marginTop: '10px'
  },
  label: {
    transform: 'translate(0px, 0px) scale(1)',
    fontSize: '12px',
    lineHeight: '20px',
    color: 'rgba(0,0,0,.6)'
  },
  input: {
    mt: '22px',
    px: '10px',
    py: '6px',
    background: 'white',
    border: '1px solid #DCDBDC',
    borderRadius: '4px',
    '.MuiInputBase-input': {
      padding: 0
    },
    fontSize: '14px'
  },
  formulationsBlock: {
    display: 'flex',
    flexDirection: 'column',
    mt: '10px'
  },
  formulationsIcon: {
    color: COLOR_GREY_LIGHTER,
    width: '12px',
    height: '12px'
  },
  formulationsTitle: {
    fontSize: '10px',
    textTransform: 'uppercase',
    color: COLOR_GREY_LIGHTER
  },
  formulationsList: {
    display: 'flex',
    maxWidth: 'calc(100% - 40px)',
    gap: '4px',
    flexWrap: 'wrap'
  },
  formulationsChip: {
    fontSize: '12px',
    borderRadius: '6px',
    '&:hover': {
      cursor: 'pointer'
    }
  }
};

const FormulationDialog = ({ parameterId, columns, value, onClose, onSubmit }) => {
  const { track } = useAnalytics();

  const { data: formulasData } = useQuery(FORMULAS);

  const { description, calculation, unit, setDescription, setCalculation, setUnit } = useCalculationForm(value);

  const handleSubmit = useCallback(async () => {
    track(TRACK_CALCULATED_FIELDS.update, {
      component: componentNames.TABLE_STRUCTURE
    });

    onSubmit({
      description,
      unit,
      calculation: calculation,
    });
  }, [track, onSubmit, description, unit, calculation]);

  const columnsList = useMemo(() => {
    return columns.reduce((acc, tableProtocol) => {
      const parameters = tableProtocol.tableParameters?.map(param => ({
        id: param.id,
        title: `${param.title ?? param.titleTableItem.title}`,
        fullTitle: `${tableProtocol.title} / ${param.title ?? param.titleTableItem.title}`
      }));

      return acc.concat(parameters ?? []).filter(column => column.id !== parameterId);
    }, []) || [];
  }, [columns, parameterId]);

  const formulasList = useMemo(() => {
    return formulasData?.mathOperations || [];
  }, [formulasData?.mathOperations]);

  const formulasNamesList = useMemo(() => {
    return formulasList.map(formula => formula.functionName);
  }, [formulasList]);

  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        '.MuiDialog-paper': styles.dialog
      }}
    >
      <DialogTitle>
        Set calculated value
      </DialogTitle>

      <DialogContent>
        <FormControl
          fullWidth
        >
          <InputLabel
            variant="outlined"
            disableAnimation
            shrink
            sx={styles.label}
          >
            Description
          </InputLabel>

          <InputBase
            value={description}
            inputProps={{
              maxLength: 200
            }}
            sx={styles.input}
            onChange={setDescription}
            autoFocus
          />
        </FormControl>

        <FormControl
          fullWidth
          sx={styles.formControl}
        >
          <Typography
            shrink
            sx={styles.label}
            variant="outlined"
            disableAnimation
          >
            Field value equal to
          </Typography>

          <FormulaEditor
            value={calculation}
            setValue={setCalculation}
            columns={columnsList}
            formulasNamesList={formulasNamesList}
          />
        </FormControl>

        <UnitsBlock
          setUnit={setUnit}
          unit={unit}
        />

        <Box
          sx={styles.formulationsBlock}
        >
          <Box
            display="flex"
            flexDirection="row"
            gap="4px"
            alignItems="center"
            mb="8px"
          >
            <CalculationIcon
              sx={styles.formulationsIcon}
            />

            <Typography
              sx={styles.formulationsTitle}
            >
              Formulations
            </Typography>
          </Box>

          <Box sx={styles.formulationsList}>
            {formulasList.map(formula =>
              <Tooltip
                key={formula.id}
                title={
                  <Box>
                    <Typography sx={{ fontSize: '12px' }}>
                      {formula.title}
                    </Typography>

                    <Typography sx={{ fontSize: '12px' }}>
                      {formula.description}
                    </Typography>
                  </Box>
                }
                arrow
              >
                <Chip
                  label={`${formula.functionName}()`}
                  sx={styles.formulationsChip}
                />
              </Tooltip>
            )
            }
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          p: '16px'
        }}
      >
        <Button
          onClick={onClose}
        >
          cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

FormulationDialog.propTypes = {
  parameterId: PT.string.isRequired,
  columns: PT.array.isRequired,
  value: PT.object,
  onClose: PT.func.isRequired,
  onSubmit: PT.func.isRequired,
};

export default FormulationDialog;
