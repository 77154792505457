import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
import QrCodeIcon from '@mui/icons-material/QrCode';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

import CopyClipboard from '../../../../CopyClipboard';

import { IconButtonStyled } from '../styles';

const FullScreenItemActions = ({
  isCopied,
  onCopy,
  itemLink,
  onViewQR,
  onClone,
  canEdit,
  onDelete,
  readOnly
}) => {
  return (
    <Box flexShrink="0">
      {!readOnly ?
        <CopyClipboard
          tooltip
          tooltipProps={{
            open: isCopied,
            title: 'Copied',
            placement: 'top',
          }}
          onCopy={onCopy}
          text={itemLink}
          wrapperStyles={{
            width: '36px',
            display: 'inline-block'
          }}
        >
          <Tooltip title={'Copy item URL'}>
            <IconButtonStyled>
              <LinkIcon fontSize="small" />
            </IconButtonStyled>
          </Tooltip>
        </CopyClipboard>
        : null
      }

      {!readOnly ?
        <Tooltip title={'View QR code'}>
          <IconButtonStyled onClick={onViewQR}>
            <QrCodeIcon fontSize="small" />
          </IconButtonStyled>
        </Tooltip>
        : null
      }

      {!readOnly ?
        <Tooltip title={'Duplicate item'}>
          <IconButtonStyled onClick={onClone}>
            <ContentCopyIcon fontSize="small" />
          </IconButtonStyled>
        </Tooltip>
        : null
      }

      {
        canEdit ?
          <Tooltip title={'Delete item'}>
            <IconButtonStyled onClick={onDelete}>
              <DeleteIcon fontSize="small" />
            </IconButtonStyled>
          </Tooltip>
          :
          null
      }
    </Box>
  );
};

FullScreenItemActions.propTypes = {
  isCopied: PT.bool,
  onCopy: PT.func.isRequired,
  itemLink: PT.string,
  onViewQR: PT.func.isRequired,
  onClone: PT.func.isRequired,
  canEdit: PT.bool,
  onDelete: PT.func,
  readOnly: PT.bool
};

export default FullScreenItemActions;
