import React, { useCallback, useRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const PanelResizeControl = ({ className, panelWidth, onResize, itemType }) => {
  const ref = useRef();
  const handleResizeRef = useRef(null);

  const handleResize = useCallback((e, startX) => {
    return onResize(panelWidth + e.clientX - startX);
  }, [onResize, panelWidth]);

  const handleStartResize = useCallback(ev => {
    handleResizeRef.current = new AbortController();

    ref.current.addEventListener('pointermove', (e) => handleResize(e, ev.clientX), {
      signal: handleResizeRef.current.signal
    });
    ref.current.setPointerCapture(ev.pointerId);
  }, [handleResize]);

  const handleStopResize = useCallback((ev) => {
    handleResizeRef.current.abort();
    ref.current.releasePointerCapture(ev.pointerId);

    window.localStorage.setItem(itemType, panelWidth);
  }, [itemType, panelWidth]);

  return (
    <div
      className={className}
      ref={ref}
      onPointerDown={handleStartResize}
      onPointerUp={handleStopResize}
    >
      &nbsp;
    </div>
  );
};

PanelResizeControl.propTypes = {
  className: PT.string.isRequired,
  panelWidth: PT.number.isRequired,
  onResize: PT.func.isRequired,
  itemType: PT.string.isRequired,
};

export default styled(PanelResizeControl)`
  user-select: none;
  position: absolute;
  width: 2px;
  height: 100%;
  left: ${({ panelWidth }) => `${panelWidth}px`};
  top: 0;
  cursor: col-resize;
`;
