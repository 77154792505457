import React, { useMemo } from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';

import SelectProtocolsAction from './SelectProtocolsAction';
import SearchInput from './SearchInput';
import ShareView from './ShareView';
import CreateItem from './CreateItem';

import { TABLE_VIEW } from '../constants';
import { hasWriteAccess } from '../../../utils/roles';

const SecondaryToolbarActions = ({
  onSearch,
  displayView,
  onOpenSelectParams,
  disableViewTools,
  disableParametersTool,
  queryId,
  dashboardRef,
  dashboardData,
  tablesById,
  queryVariables,
  handleToggleSidebar
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedTableIds = queryVariables.input?.filters?.tableIds ?? [];

  const selectedTable = tablesById[selectedTableIds[0]];

  const createItemButtonDisabled = useMemo(() => {
    return selectedTableIds.length !== 1 ||
      (selectedTable && !hasWriteAccess(selectedTable.viewerMaxRole));
  }, [selectedTableIds, selectedTable]);

  const createItemButtonTooltip = useMemo(() => {
    if(!selectedTableIds.length)
      return 'Select a table to add items';

    if(selectedTableIds.length > 1)
      return 'New items cannot be added when more than one table is selected';

    if(selectedTable && !hasWriteAccess(selectedTable.viewerMaxRole))
      return 'You should have at least "Write" permission in the selected table to add an item';

    return 'Add item';
  }, [selectedTableIds, selectedTable]);

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="4px"
    >
      <SearchInput
        initialValue={queryVariables.input.search}
        onSearch={onSearch}
        disabled={displayView !== TABLE_VIEW}
      />

      <SelectProtocolsAction
        onClick={onOpenSelectParams}
        disabled={disableParametersTool}
      />

      <ShareView
        key={displayView}
        displayView={displayView}
        itemQueryId={queryId}
        dashboardRef={dashboardRef}
        dashboardData={dashboardData}
        queryInput={queryVariables.input}
        disabled={disableViewTools}
      />

      <CreateItem
        buttonTooltip={createItemButtonTooltip}
        disabled={createItemButtonDisabled}
        table={selectedTable}
        queryVariables={queryVariables}
        handleToggleSidebar={handleToggleSidebar}
      />
    </Box>
  );
};

SecondaryToolbarActions.propTypes = {
  queryId: PT.string,
  displayView: PT.string,
  disableViewTools: PT.bool,
  disableParametersTool: PT.bool,
  dashboardRef: PT.object,
  dashboardData: PT.object,
  tablesById: PT.object,
  onSearch: PT.func.isRequired,
  onOpenSelectParams: PT.func,
  queryVariables: PT.object,
  handleToggleSidebar: PT.func.isRequired,
};

export default SecondaryToolbarActions;
