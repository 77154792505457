import { gql } from '@apollo/client/core';

export { default as GET_ITEM } from './get-item';

const LINKED_ITEM_FRAGMENT = gql`
  fragment LinkedTableItem on LinkedTableItem {
    id
    title
    code
    viewerMaxRole
    owner {
      id
      name
      ... on CurrentUser {
        avatarColor
        picture {
          downloadURL
        }
      }
    }
    linkingProtocols {
      id
      title
    }
  }
`;

export const GET_LINKED_ITEMS = gql`
  ${LINKED_ITEM_FRAGMENT}
  query LinkedItems($itemId: ID!) {
    linkedTableItems(id: $itemId) {
      to {
        ...LinkedTableItem
      }

      from {
        ...LinkedTableItem
      }
    }
  }
`;

