import React, { useRef, useCallback, useState, useMemo, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation } from '@apollo/client';

import InputBase from '@mui/material/InputBase';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import ArrowDown from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';

const GET_UNITS = gql`
  query CompositionItemUnitsGetUnits {
    units {
      id
      name
      description
    }
  }
`;

const CREATE_UNIT = gql`
  mutation CompositionItemUnitsCreateUnit($value: String!, $description: String) {
    addUnit(value: $value, description: $description) {
      id
      name
      description
    }
  }
`;

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'left'
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left'
};

const dropDownMenuProps = {
  style: {
    maxHeight: '170px'
  }
};

const keys = [' ', 'ArrowUp', 'ArrowDown', 'Enter'];

const CompositionItemUnits = ({ onChange, value, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [input, setInput] = useState(value?.name || '');

  const inputRef = useRef();
  const placeholderRef = useRef();
  const usedItemsTitleRef = useRef([]);

  const [createUnit] = useMutation(CREATE_UNIT);
  const { data, loading: fetching, updateQuery } = useQuery(GET_UNITS);

  const filteredUnits = useMemo(() =>
    (data?.units || [])
      .filter(({ description, name }) => {
        const isNewInput = !value?.name || value?.name !== input;

        return [description, name]
          .join(' ')
          .toLowerCase()
          .includes(isNewInput ? input.toLowerCase() : '');
      }
      ),
  [data?.units, input, value?.name]
  );

  const isNewUnitOptionVisible = useMemo(() =>
    shouldNewUnitDisplay(
      filteredUnits.map(i => i.name),
      input
    ),
  [filteredUnits, input]
  );

  const handleMenuOpen = useCallback(() => {
    if(fetching) return;

    setAnchorEl(inputRef.current);
  }, [fetching]);

  const handleMenuClose = useCallback((e, reason) => {
    if (!input && reason)
      onChange(null);

    setAnchorEl(null);
  }, [input, onChange]);

  const handleKeyDown = useCallback(ev => {
    const { key } = ev;

    if(keys.includes(key)) {
      ev.preventDefault();
      handleMenuOpen();
      usedItemsTitleRef.current[0].current?.focus();
    } else if(key === 'Escape') {
      handleMenuClose();
    }
  }, [handleMenuClose, handleMenuOpen]);

  const handleInputChange = useCallback(ev => {
    const input = ev.target.value;

    setInput(input);

    if (!anchorEl) {
      setAnchorEl(inputRef.current);
    }
  }, [anchorEl]);

  const handleSelectUnit = useCallback(value => () => {
    setInput(value.name);
    onChange({ unit: value });
    handleMenuClose();
  }, [onChange, handleMenuClose]);

  const handleCreateNewUnit = useCallback(async () => {
    try {
      const newUnit = await createUnit({
        variables: {
          value: input
        }
      });

      updateQuery(cache => ({
        ...cache,
        units: [...cache.units, newUnit?.data?.addUnit]
      }));

      handleSelectUnit({
        id: newUnit?.data?.addUnit?.id,
        name: newUnit?.data?.addUnit?.name
      })();
    } catch(e) {
      console.error(e);
    }
  }, [createUnit, handleSelectUnit, input, updateQuery]);

  const handleClear = useCallback(() => {
    setInput('');

    if(!anchorEl)
      onChange(null);
  }, [anchorEl, onChange]);

  useEffect(() => {
    usedItemsTitleRef.current = filteredUnits
      .map((element, i) =>
        usedItemsTitleRef.current[i] ?? createRef()
      );
  }, [filteredUnits]);

  return (
    <>
      <InputBase
        value={input}
        placeholder="Select unit"
        disabled={fetching || disabled}
        onFocus={handleMenuOpen}
        onChange={handleInputChange}
        fullWidth
        sx={{
          height: '28px',
          px: '8px',
          py: '2px',
          background: 'white',
          border: '1px solid #DCDBDC',
          borderRadius: '4px',
          '.MuiInputBase-input': {
            padding: 0
          },
          fontSize: '14px',
          lineHeight: '18px'
        }}
        endAdornment={
          <InputAdornment
            position="end"
          >
            <IconButton
              size="small"
              edge="end"
              onClick={handleClear}
              disabled={!value}
              sx={{
                padding: '2px',
                visibility: input ? 'visible' : 'hidden'
              }}
            >
              <ClearIcon fontSize="16px" />
            </IconButton>

            <ArrowDown
              sx={{ pointerEvents: 'none' }}
            />
          </InputAdornment>
        }
        inputRef={inputRef}
        inputProps={{
          onKeyDown: handleKeyDown,
          autoComplete: 'off'
        }}
      />

      <Popover
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        disableAutoFocus
        slotProps={{
          paper: dropDownMenuProps
        }}
        onMouseDown={ev => ev.preventDefault()}
      >
        <MenuList>
          {filteredUnits.map(({ id, name, description }, index) => (
            <MenuItem
              key={name}
              ref={usedItemsTitleRef.current[index] || placeholderRef}
              onClick={handleSelectUnit({ id, name })}
            >
              {name} {description ? `(${description})` : ''}
            </MenuItem>
          ))}

          {isNewUnitOptionVisible
            ? (
              <MenuItem onClick={handleCreateNewUnit}>
                Create unit {`"${input}"`}
              </MenuItem>
            )
            : null
          }
        </MenuList>
      </Popover>
    </>
  );
};

CompositionItemUnits.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  disabled: PropTypes.bool
};

export default CompositionItemUnits;

function shouldNewUnitDisplay(items, value) {
  // don't display "Create unit" option
  // if input is empty
  if(!value.trim().length)
    return false;

  // display "Create unit" option
  // if filtered list is empty
  if(!items.length)
    return true;

  // don't display "Create unit" option
  // if input matches exact option
  return !items.some(v => v === value);
}
