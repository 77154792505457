import React from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import TitleBlock from './Header/TitleBlock';
import InfoBlock from './Header/InfoBlock';
import TabsBlock from './TabsBlock';

import { GET_LINKED_ITEMS } from './gql';

import { Header } from './styles';

const ItemView = ({
  id,
  itemData,
  defaultTab,
  openState,
  onItemRefetchRequest,
  actions: ActionsBlock,
  search
}) => {
  const { data: linkedData } = useQuery(GET_LINKED_ITEMS, {
    skip: !id,
    variables: { itemId: id },
  });

  return (
    <Box
      sx={{
        padding: '24px',
        height: '100%',
        width: '100%',
        overflowY: 'auto'
      }}
    >
      {itemData ?
        <Header>
          <TitleBlock
            data={itemData}
            search={search}
          />

          <ActionsBlock />
        </Header>
        : null
      }

      <InfoBlock
        id={itemData?.id}
        timestamp={itemData?.dateFabricated ?? itemData?.dateCreated}
        description={itemData?.description}
        search={search}
        viewerMaxRole={itemData?.table.viewerMaxRole}
      />

      <TabsBlock
        linkedItems={linkedData?.linkedTableItems}
        data={itemData}
        defaultTab={defaultTab}
        sidebarState={openState}
        onItemRefetchRequest={onItemRefetchRequest}
      />
    </Box>
  );
};

ItemView.propTypes = {
  id: PropTypes.string.isRequired,
  openState: PropTypes.string.isRequired,
  itemData: PropTypes.object,
  actions: PropTypes.func.isRequired,
  defaultTab: PropTypes.string,
  onItemRefetchRequest: PropTypes.func,
  search: PropTypes.string
};

export default ItemView;
