import React, { memo, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Virtuoso } from 'react-virtuoso';

import TableCheckboxCell from '../Table/TableCheckboxCell';
import TableRow from './TableRow';
import { BodyWrapper, HeaderWrapper, TableCell, TableHead } from './styles';

import { fullyCheckedIds } from '../../services/samples/utils';
/*
  It's a visible virtual (partly hidden from user) viewport height.
  Unit: pixels.
  Purpose: To avoid frequently/unnecessesary items unmounting.
*/

const ITEMS_LIST_VIEWPORT_PX = 10_000;

const Table = ({
  mobile = false,
  onCheckAll,
  isCheckedAll,
  isIndeterminate,
  items,
  sortedField,
  sortAsc,
  columnsMap,
  disabled,
  onSort,
  scrollElement,
  hideTable,
  itemsById,
  ...itemProps
}) => {
  const headerRef = useRef();
  const tableRef = useRef();
  const ticking = useRef(false);

  const updateHeaderScroll = useCallback(() => {
    const lastPosition = tableRef.current.scrollLeft;

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        headerRef.current.scrollLeft = lastPosition;
        ticking.current = false;
      });

      ticking.current = true;
    }
  }, []);

  const updateBodyScroll = useCallback(() => {
    const lastPosition = headerRef.current.scrollLeft;

    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        tableRef.current.scrollLeft = lastPosition;
        ticking.current = false;
      });

      ticking.current = true;
    }
  }, []);

  useEffect(() => {
    const header = headerRef.current;
    const body = tableRef.current;

    header.addEventListener('scroll', updateBodyScroll);
    body.addEventListener('scroll', updateHeaderScroll);

    return () => {
      header.removeEventListener('scroll', updateBodyScroll);
      body.removeEventListener('scroll', updateHeaderScroll);
    };
  }, [updateBodyScroll, updateHeaderScroll]);

  const getObjectsData = useCallback((id) => {
    return itemsById[id]?.objects || {};
  }, [itemsById]);

  return (
    <>
      <HeaderWrapper ref={headerRef}>
        <TableHead mobile={mobile}>
          <TableCheckboxCell
            onCheck={onCheckAll}
            checked={isCheckedAll}
            indeterminate={isIndeterminate}
          />

          {columnsMap.map(i =>
            <TableCell
              mobile={mobile}
              className={`${i.value}${i.sortable ? ' sortable' : ''}`}
              key={i.value}
              onClick={onSort.bind(null, {
                sortAsc: !sortAsc,
                sortBy: i.sortProp,
              })}
            >
              {i.sortable && i.sortProp !== sortedField &&
                <div className="sort-icon-container">
                  <ArrowUpIcon className="arrow-up" />
                  <ArrowDownIcon className="arrow-down" />
                </div>
              }

              {i.sortable && i.sortProp === sortedField && !sortAsc &&
                <ArrowUpIcon className="arrow-up" />
              }

              {i.sortable && i.sortProp === sortedField && sortAsc &&
                <ArrowDownIcon className="arrow-down" />
              }
              {i.label}
            </TableCell>
          )}
        </TableHead>
      </HeaderWrapper>

      <BodyWrapper ref={tableRef}>
        {!hideTable &&
        <Virtuoso
          customScrollParent={scrollElement}
          data={items}
          increaseViewportBy={ITEMS_LIST_VIEWPORT_PX}
          itemContent={(idx, i) =>
            <TableRow
              {...itemProps}
              itemsById={itemsById}
              key={i.id || idx}
              isChecked={Boolean(itemsById[i.id]?.checked)}
              isDisabled={disabled && Boolean(fullyCheckedIds(itemsById).includes(i.id))}
              listItemIndex={idx}
              mobile={mobile}
              columnsMap={columnsMap}
              data={i}
              sampleId={i.id}
              objectsById={getObjectsData(i.id)}
            />
          }
        />
        }
      </BodyWrapper>
    </>
  );
};

Table.propTypes = {
  onSort: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  onCheckAll: PropTypes.func.isRequired,
  isCheckedAll: PropTypes.bool.isRequired,
  isIndeterminate: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  sortedField: PropTypes.string,
  sortAsc: PropTypes.bool,
  columnsMap: PropTypes.array.isRequired,
  scrollElement: PropTypes.instanceOf(Element),
  hideTable: PropTypes.bool,
  disabled: PropTypes.bool,
  itemsById: PropTypes.object.isRequired
};

export default memo(Table);
