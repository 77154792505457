import { gql } from '@apollo/client';

export const GET_NOTIFICATIONS_FRAGMENT = gql`
  fragment NotificationListEdge on NotificationListEdge {
    node {
      id
      read
      timestamp
      eventType

      ... on CommentNotification {
        user {
          name
          ... on CurrentUser {
            avatarColor
            picture {
              downloadURL
            }
          }
        }
        item {
          id
          title
          archived
        }
      }

      ... on ShareViewNotification {
        url
        icon
        title
        user {
          name

          ... on CurrentUser {
            avatarColor
            picture {
              downloadURL
            }
          }
        }
      }

      ... on ShareFolderNotification {
        role
        folder {
          id
          title
        }
        user {
          id
          name
        }
      }

      ... on ShareTableNotification {
        role
        table {
          id
          title
        }
        user {
          id
          name
        }
      }
    }
  }
`;
