import React from 'react';
import PT from 'prop-types';

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import CopyClipboard from '../../../../CopyClipboard';

const ShortItemActions = ({
  onOpen,
  anchorEl,
  onClose,
  isCopied,
  onCopyURL,
  itemLink,
  onViewQR,
  onClone,
  canEdit,
  onDelete,
  readOnly
}) => {
  return (
    <>
      {canEdit || !readOnly ?
        <Tooltip title="Menu">
          <IconButton onClick={onOpen}>
            <MoreVertIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        : null
      }

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onClose}
      >
        {!readOnly ?
          <CopyClipboard
            tooltip
            tooltipProps={{
              open: isCopied,
              title: 'Copied',
              placement: 'top',
            }}
            onCopy={onCopyURL}
            text={itemLink}
          >
            <MenuItem>
              Copy item URL
            </MenuItem>
          </CopyClipboard>
          : null
        }

        {!readOnly ?
          <MenuItem onClick={onViewQR}>View QR code</MenuItem>
          : null
        }

        {!readOnly ?
          <MenuItem onClick={onClone}>Duplicate item</MenuItem>
          : null
        }

        {
          canEdit ?
            <Box>
              <Divider />

              <MenuItem onClick={onDelete}>Delete</MenuItem>
            </Box> :
            null
        }
      </Menu>
    </>
  );
};

ShortItemActions.propTypes = {
  anchorEl: PT.object,
  isCopied: PT.bool,
  itemLink: PT.string.isRequired,
  canEdit: PT.bool,
  onOpen: PT.func.isRequired,
  onClose: PT.func.isRequired,
  onCopyURL: PT.func.isRequired,
  onClone: PT.func.isRequired,
  onViewQR: PT.func.isRequired,
  onArchive: PT.func,
  onDelete: PT.func,
  readOnly: PT.bool,
};

export default ShortItemActions;
