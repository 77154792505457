import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Editable, ReactEditor, Slate, withReact } from 'slate-react';
import { createEditor, Range, Editor, Transforms, Text } from 'slate';
import { withHistory } from 'slate-history';
import PT from 'prop-types';

import Popover from '@mui/material/Popover';

import RenderedElement from './RenderedElement';
import ColumnsList from './ColumnsList';
import Leaf from './Leaf';

import withColumns from './utils/withColumns';
import EditableWrapper from './styles';

const anchorOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
};

const FormulaEditor = ({ value, setValue, columns, formulasNamesList }) => {
  const [editor] = useState(() => withColumns(withHistory(withReact(createEditor()))));
  const [rangeRef, setRangeRef] = useState();
  const [anchorPosition, setAnchorPosition] = useState(null);
  const [index, setIndex] = useState(0);//index of the active column
  const [search, setSearch] = useState('');

  const columnsList = useMemo(() => {
    if(!search)
      return columns;

    return columns.filter(column => {
      return column.title.toLowerCase().includes(search.toLowerCase());
    });
  }, [columns, search]);

  const handleEditorStateChange = useCallback((value) => {
    setValue(value);

    const { selection } = editor;

    if(selection && Range.isCollapsed(selection)) {
      const [start] = Range.edges(selection);
      const charBefore = Editor.before(editor, start, { unit: 'character' });
      const charBeforeRange = charBefore && Editor.range(editor, charBefore, start);
      const char = charBeforeRange && Editor.string(editor, charBeforeRange);
      let beforeRange;

      if(char === '#') {
        beforeRange = charBeforeRange;
      } else {
        const wordBefore = Editor.before(editor, start, { unit: 'word' });
        let before = wordBefore && Editor.before(editor, wordBefore);
        beforeRange = before && Editor.range(editor, before, start);
      }

      const beforeText = beforeRange && Editor.string(editor, beforeRange);
      const beforeMatch = beforeText && beforeText.match(/^#/);

      if(beforeMatch) {
        setRangeRef(beforeRange);
        setSearch(beforeText.substring(1) || '');
        setIndex(0);
        return;
      }
    }

    setRangeRef(null);
  }, [editor, setValue]);

  const clearAutocomplete = useCallback(() => {
    setRangeRef(null);
    setAnchorPosition(null);
    setSearch('');
  }, []);

  const handleSelectColumn = useCallback(({ id, title }) => {
    if(!rangeRef) return;

    const target = {
      ...rangeRef,
      anchor: {
        ...rangeRef.anchor,
        offset: rangeRef.anchor.offset
      }
    };
    Transforms.select(editor, target);
    editor.insertColumn({ id, title });

    clearAutocomplete();
  }, [editor, rangeRef, clearAutocomplete]);

  const handleKeyDown = useCallback(event => {
    if (anchorPosition) {
      switch (event.key) {
        case 'ArrowDown': {
          event.preventDefault();
          const prevIndex = index >= columns.length - 1 ? 0 : index + 1;

          setIndex(prevIndex);
          break;
        }

        case 'ArrowUp': {
          event.preventDefault();
          const nextIndex = index <= 0 ? columns.length - 1 : index - 1;

          setIndex(nextIndex);
          break;
        }

        case 'Tab':
        case 'Enter':
          event.preventDefault();
          handleSelectColumn(columnsList[index]);
          break;

        case 'Escape':
          event.preventDefault();

          clearAutocomplete();
          break;

        default:
          return;
      }
    }

    if (event.key === 'Enter')
      event.preventDefault();
  }, [anchorPosition, handleSelectColumn, columnsList, index, clearAutocomplete, columns.length]);

  const decorate = useCallback(([node, path]) => {
    const ranges = [];
    const regex = new RegExp(`(${formulasNamesList.join('|')})(\\()`, 'gi');

    if (Text.isText(node)) {
      const { text } = node;
      let match;

      while ((match = regex.exec(text)) !== null) {
        const start = match.index;
        const end = start + match[1].length;

        ranges.push({
          anchor: { path, offset: start },
          focus: { path, offset: end },
          color: 'rgba(23, 114, 220, 1)',
        });
      }
    }

    return ranges;
  }, [formulasNamesList]);

  useEffect(() => {
    if (rangeRef && columnsList?.length > 0) {
      const domRange = ReactEditor.toDOMRange(editor, rangeRef);
      const rect = domRange.getBoundingClientRect();

      setAnchorPosition({
        top: rect.top + window.pageYOffset + 24,
        left: rect.left + window.pageXOffset
      });
    } else {
      setAnchorPosition(null);
    }
  }, [columnsList?.length, editor, rangeRef]);

  useEffect(() => {
    const { selection } = editor;
    if (ReactEditor.isFocused(editor) && !selection) {
      Transforms.select(editor, {
        anchor: { path: [0, 0], offset: 0 },
        focus: { path: [0, 0], offset: 0 }
      });
    }
  }, [editor]);

  const renderLeaf = useCallback(props => <Leaf {...props} />, []);

  return (
    <>
      <Slate
        editor={editor}
        onChange={handleEditorStateChange}
        value={value}
      >
        <EditableWrapper>
          <Editable
            decorate={decorate}
            renderElement={RenderedElement}
            renderLeaf={renderLeaf}
            onKeyDown={handleKeyDown}
            placeholder="Start typing or choose a function/column. Tip: Use '#' followed by the column name to reference a table column (e.g., #ColumnName)"
          />
        </EditableWrapper>
      </Slate>

      {anchorPosition ?
        <Popover
          sx={{
            maxHeight: '200px',
            maxWidth: '700px',
          }}
          open
          anchorReference="anchorPosition"
          anchorPosition={anchorPosition}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          hideBackdrop
          disableAutoFocus
          disableEnforceFocus
        >
          <ColumnsList
            onSelectColumn={handleSelectColumn}
            focusIndex={index}
            columns={columnsList}
          />
        </Popover> :
        null
      }
    </>
  );
};

FormulaEditor.propTypes = {
  value: PT.array,
  setValue: PT.func.isRequired,
  columns: PT.array.isRequired,
  formulasNamesList: PT.array.isRequired,
};

export default FormulaEditor;
