import { useMemo } from 'react';
import pick from 'lodash/pick';

const TYPES = {
  ITEM: 'Item',
  FOLDER: 'Folder',
  TABLE: 'Table',
};

function useItemsList(data, {
  search,
  sortSettings,
  isCheckedItemsFilterActive,
  itemsById,
}) {
  const list = useMemo(() => {
    if(!data?.trash)
      return [];

    let parsedData = [];

    const items = data?.trash.tableItems.map((item) => ({
      ...item,
      path: item.table.path.reduce((acc, i) => [...acc, i.title], []).concat(item.table.title).join('/'),
      type: TYPES.ITEM
    }));

    const folders = data?.trash.folders.map((item) => ({
      ...item,
      type: TYPES.FOLDER,
      path: item.path.length ? item.path.reduce((acc, i) => [...acc, i.title], []).join('/') : '/'
    }));

    const tables = data?.trash.tables.map((item) => ({
      ...item,
      type: TYPES.TABLE,
      path: item.path.length ? item.path.reduce((acc, i) => [...acc, i.title], []).join('/') : '/'
    }));

    parsedData = [...items, ...folders, ...tables];

    return parsedData;
  }, [data?.trash]);

  const filteredList = useMemo(() =>
    getFilteredSamples(
      list,
      isCheckedItemsFilterActive,
      itemsById
    ).filter(({ title }) => title.toLowerCase().includes(search.toLowerCase())), [
    search,
    list,
    isCheckedItemsFilterActive,
    itemsById
  ]
  );

  const mappedSamples = useMemo(() =>
    filteredList.map(i => pick(i, [
      'id',
      'title',
      'creator',
      'trashedTimestamp',
      'path',
      'type',
    ]))
      .sort((first, second) => {
        if (!sortSettings.sortBy)
          return true;

        if (sortSettings.sortBy === 'creator') {
          return sortSettings.sortAsc ? (first.creator.name || '').localeCompare(second.creator.name || '') :
            (second.creator.name || '').localeCompare(first.creator.name || '');
        }

        if (typeof first[sortSettings.sortBy] === 'string') {
          return sortSettings.sortAsc ? first[sortSettings.sortBy].localeCompare(second[sortSettings.sortBy]) :
            second[sortSettings.sortBy].localeCompare(first[sortSettings.sortBy]);
        }

        return sortSettings.sortAsc ? first[sortSettings.sortBy] - second[sortSettings.sortBy] :
          second[sortSettings.sortBy] - first[sortSettings.sortBy];
      }),
  [filteredList, sortSettings.sortAsc, sortSettings.sortBy]
  );

  return {
    mappedSamples,
    filteredListCount: filteredList.length,
  };
}

export default useItemsList;

function getFilteredSamples(
  listCollection,
  isSelectedIdsActive,
  itemsById
) {
  if (isSelectedIdsActive) {
    return listCollection.filter(i => itemsById[i.id].checked);
  }

  return listCollection;
}
