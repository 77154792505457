import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ItemType = ({ type }) => {
  return (
    <Box>
      <Typography
        sx={{
          fontSize: '14px'
        }}
      >
        {type}
      </Typography>
    </Box>
  );
};

ItemType.propTypes = {
  type: PropTypes.string.isRequired,
};

export default ItemType;
