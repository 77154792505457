import React from 'react';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import TextWithLink from '../../../../../TextWithLink';
import PropTypes from 'prop-types';

const LinkedProtocolToItem = ({ link, title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '4px',
        alignItems: 'center'
      }}
    >
      <OpenInNewIcon
        color="primary"
        sx={{
          height: '14px',
          width: '14px'
        }}
      />

      <TextWithLink
        link={link}
        linkProps={{
          target: '_blank'
        }}
      >
        <Tooltip
          title={`${title}`}
          placement="left"
        >
          <Typography
            noWrap
            sx={{
              textOverflow: 'ellipsis',
              maxWidth: '212px'
            }}
          >
            {title}
          </Typography>
        </Tooltip>
      </TextWithLink>
    </Box>
  );
};

LinkedProtocolToItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string
};

export default LinkedProtocolToItem;
