import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';

import SavedViews from './SavedViews';

const PrimaryToolbarActions = ({
  disableViewTools,
  savedViewsProps
}) => {

  return (
    <Box
      display="flex"
      alignItems="center"
      gap="14px"
    >
      <SavedViews
        disableSaving={disableViewTools}

        {...savedViewsProps}
      />
    </Box>
  );
};

PrimaryToolbarActions.propTypes = {
  disableViewTools: PropTypes.bool,
  savedViewsProps: PropTypes.shape({
    onSelect: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onRename: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDiscard: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    selectedView: PropTypes.shape({
      id: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      dashboardId: PropTypes.string,
      dashboardStateId: PropTypes.string,
      tableItemQueryId: PropTypes.string.isRequired,
      changed: PropTypes.bool.isRequired,
    }),
  }),
};

export default PrimaryToolbarActions;
