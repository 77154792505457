import { gql } from '@apollo/client';

export const FETCH_TRASH = gql`
  query FetchTrash {
    trash {
      tableItems {
        id
        title
        trashedTimestamp
        creator {
          id
          name
            ... on CurrentUser {
            userName
            pictureDownloadURL
          }
        }
        table {
          id
          title
          path {
            id
            title
          }
        }
      }
      folders {
        id
        title
        trashedTimestamp
        path {
          id
          title
        }
        creator {
          id
          name
            ... on CurrentUser {
            userName
            pictureDownloadURL
          }
        }
      }
      tables {
        id
        title
        trashedTimestamp
        creator {
          id
          name
            ... on CurrentUser {
            userName
            pictureDownloadURL
          }
        }
        path {
          id
          title
        }
      }
    }
  }
`;

export const GET_VIEWER = gql`
  query ArchiveItemsViewerInfo {
    viewer {
      id
      userId
      name
      avatarColor
      picture {
        downloadURL
      }
    }
  }
`;
