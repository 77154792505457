import { useCallback, useState } from 'react';
import throttle from 'lodash/throttle';

import {
  TITLE_PANEL_WIDTH,
  TITLE_DEFAULT_WIDTH_PX,
} from '../../components/PanelResizeControl/constants';

const useTableColumnWidth = () => {

  const [titleWidth, setTitleWidth] = useState(() => {
    const savedPanelWidth = Number.parseInt(window.localStorage.getItem(TITLE_PANEL_WIDTH));

    return Number.isNaN(savedPanelWidth) ? TITLE_DEFAULT_WIDTH_PX : savedPanelWidth;
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle((newValue, defaultWidth) => {
      if(newValue > defaultWidth && newValue < window.innerWidth / 3)
        setTitleWidth(newValue);
      else if(newValue <= defaultWidth)
        setTitleWidth(defaultWidth);
    }, 20),
    []
  );

  const handleUpdateTitleWidth = useCallback((newValue) => {
    handleResize(newValue, TITLE_DEFAULT_WIDTH_PX);
  }, [handleResize]);

  return {
    titleWidth,
    onUpdateTitleWidth: handleUpdateTitleWidth
  };
};

export default useTableColumnWidth;
