import React from 'react';
import PT from 'prop-types';
import { gql, useQuery } from '@apollo/client';

import SvgIcon from '@mui/material/SvgIcon';

import { icons } from '../../../scenes/AllItems/Toolbar/ViewsMenu/constants';
import { COLOR_PRIMARY_DARK } from '../../../styles';

const INSIGHTS = gql`
  query Dashboards {
    dashboards {
      id
      icon
    }
  }
`;

const SavedViewIcon = (props) => {
  const { dashboardId, selected, styles = {}, ...rest } = props;
  const { data: insightsData } = useQuery(INSIGHTS);

  const dashboardIcon = insightsData?.dashboards.find(i => i.id === dashboardId)?.icon;
  const Icon = icons[dashboardIcon] ?? icons.TABLE;

  return (
    <SvgIcon
      component={Icon}
      inheritViewBox
      sx={{
        fontSize: '14px',
        mr: '5px',
        color: selected ? COLOR_PRIMARY_DARK : '#666',
        ...styles
      }}
      {...rest}
    />
  );
};

SavedViewIcon.propTypes = {
  dashboardId: PT.string,
  selected: PT.bool,
  styles: PT.object,
};

export default SavedViewIcon;
