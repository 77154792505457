import React, { forwardRef } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

const CELL_PADDING_X = 6;
export const CELL_PADDING = CELL_PADDING_X * 2;

const TableCell = forwardRef(({ children, className, ...props }, ref) => (
  <td ref={ref} className={className} {...props}>
    {children}
  </td>
));

TableCell.propTypes = {
  children: PT.oneOfType([
    PT.arrayOf(PT.node),
    PT.node
  ]),
  className: PT.string
};

export default styled(TableCell).withConfig({
  shouldForwardProp: (prop) => !['maxWidth', 'value'].includes(prop)
})`
  vertical-align: middle;
  padding: 0 ${({ value }) => value ? '0' : CELL_PADDING_X}px;
  max-width: ${({ maxWidth }) => maxWidth != null ? `${maxWidth}px` : 'none'};
`;
