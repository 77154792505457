import React, { memo, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';

import Divider from '@mui/material/Divider';

import TableRowContent from './TableRowContent';

import {
  TableRowStyled,
  TableCell,
  Checkbox,
} from './styles';

const TableRow = ({
  data,
  isChecked,
  isDisabled,
  mobile,
  columnsMap,
  onCheck,
  renderItemActions,
  objectsById = {}
}) => {

  const { ref } = useInView({
    rootMargin: '-140px 0px -40px', // handling a sticky-header margin
  });

  const handleCheck = useCallback(() => {
    onCheck(data.id, !isChecked);
  }, [onCheck, data.id, isChecked]);

  const isIndeterminate = useMemo(
    () => !isChecked &&
      Object.values(objectsById || {}).some(({ checked }) => checked),
    [objectsById, isChecked]
  );

  return (
    <TableRowStyled>
      {isDisabled && <div className="table-cell-disable-banner" />}

      <div className="table-cell-main" ref={ref}>
        <TableCell
          className="checkbox-cell"
          mobile={mobile}
        >
          <Checkbox
            color="primary"
            checked={isChecked}
            disabled={isDisabled}
            onChange={handleCheck}
            indeterminate={isIndeterminate}
            className={isIndeterminate ? 'indeterminate' : ''}
            disableRipple
          />
        </TableCell>

        {columnsMap.map(c =>
          <TableCell
            mobile={mobile}
            className={`${c.value}`}
            key={`${c.value}${data.id}`}
          >
            <TableRowContent
              type={c.value}
              data={data}
              renderItemActions={renderItemActions}
            />
          </TableCell>
        )}
      </div>

      <Divider />
    </TableRowStyled>
  );
};

TableRow.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    type: PropTypes.string,
    trashedTimestamp: PropTypes.number,
    path: PropTypes.string,
    creator: PropTypes.object
  }).isRequired,
  isChecked: PropTypes.bool.isRequired,
  isCheckedAll: PropTypes.bool,
  isDisabled: PropTypes.bool.isRequired,
  mobile: PropTypes.bool,
  columnsMap: PropTypes.array.isRequired,
  objectsById: PropTypes.object,
  onArchive: PropTypes.func,
  onAddProtocol: PropTypes.func.isRequired,
  onAddMeasurement: PropTypes.func.isRequired,
  onCheck: PropTypes.func.isRequired,
  renderItemActions: PropTypes.func,
  onUpdateDescription: PropTypes.func,
  itemsById: PropTypes.object.isRequired,
};

export default memo(TableRow);
