import React from 'react';
import dateFnsFormat from 'date-fns/format';
import PropTypes from 'prop-types';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const DeletedDate = ({ timestamp }) => {
  const date = new Date(timestamp);

  return (
    <Box>
      <Typography
        sx={{
          fontSize: '14px'
        }}
      >
        {dateFnsFormat(date, 'dd/MM/yyyy')}
      </Typography>
    </Box>
  );
};

DeletedDate.propTypes = {
  timestamp: PropTypes.number.isRequired,
};

export default DeletedDate;
