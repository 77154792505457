import pickBy from 'lodash/pickBy';

export const getBaseUrl = url => {
  const paramsStartIndex = url.indexOf('?');

  if ( paramsStartIndex > -1 ){
    url = url.substr(0, paramsStartIndex);
  }

  return url;
};

export const checkedIds = (itemsById) => {
  return pickBy(itemsById, (value,) => {
    return value.checked || Object.values(value.objects)
      .some(({ checked }) => checked);
  });
};
export const fullyCheckedIds = (itemsById) => {
  return Object.keys(itemsById).filter(id => itemsById[id].checked);
};

export function itemsResetAndDeletion({
  updateQuery,
  itemIdsToDelete,
  folderIdsToDelete,
  tableIdsToDelete,
  onItemsListReset,
}) {
  updateQuery(cache => {
    return {
      ...cache,
      trash: {
        ...cache.trash,
        tableItems: !itemIdsToDelete ? cache.trash.tableItems : cache.trash.tableItems.filter(i =>
          !itemIdsToDelete.includes(i.id)
        ),
        folders: !folderIdsToDelete ? cache.trash.folders : cache.trash.folders.filter(i =>
          !folderIdsToDelete.includes(i.id)
        ),
        tables: !tableIdsToDelete ? cache.trash.tables :  cache.trash.tables.filter(i =>
          !tableIdsToDelete.includes(i.id)
        )
      }
    };
  });

  onItemsListReset([...(itemIdsToDelete || []), ...(folderIdsToDelete || []), ...(tableIdsToDelete || [])]);
}
