export * from '../../constants';
export const ARCHIVED_ITEMS_CLEANUP = 'ARCHIVED_ITEMS_CLEANUP';
export const ITEM_FILTER_PARAMS = new Map([
  ['CREATOR', 'CREATOR'],
  ['TEAM_SHARING', 'TEAM_SHARING'],
  ['USER_SHARING', 'USER_SHARING'],
  ['LABEL', 'LABEL'],
  ['ROLE', 'ROLE'],
  ['ITEM_TYPE', 'ITEM_TYPE']
]);

export const IAM_ROLES = new Map([
  ['READ', 'READ'],
  ['WRITE', 'WRITE'],
  ['ADMIN', 'ADMIN']
]);

export const ITEM_ACCESS = new Map([
  ['NO_ACCESS', 'NO_ACCESS'],
]);

export const PROTOCOL_LINE_TYPE = new Map([
  ['SAMPLE_ID', 'SAMPLE_ID'],
  ['PARAMETER', 'PARAMETER'],
]);

export const VALUE_TYPE = {
  QUANTITY: 'QUANTITY',
  BOOLEAN: 'BOOLEAN',
  TEXT: 'TEXT',
  LINK: 'LINK',
  CALCULATION: 'CALCULATION'
};

export const TITLE_TYPES = [{
  value: PROTOCOL_LINE_TYPE.get('PARAMETER'),
  title: 'Free text',
}, {
  value: PROTOCOL_LINE_TYPE.get('SAMPLE_ID'),
  title: 'Link to item',
}];

export const SORT_SETTINGS = 'SORT_SETTINGS';
export const TRASH_SORT_SETTINGS = 'ARCHIVE_SORT_SETTINGS';
