import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

const ItemLocation = ({ location }) => {
  return (
    <Box sx={{ maxWidth: '100%' }}>
      <Tooltip title={location}>
        <Typography
          noWrap
          sx={{
            fontSize: '14px'
          }}
        >
          {location}
        </Typography>
      </Tooltip>
    </Box>
  );
};

ItemLocation.propTypes = {
  location: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string
  ]),
};

export default ItemLocation;
