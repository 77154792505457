import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAnalytics } from 'use-analytics';

import withStyles from '@mui/styles/withStyles';

import ClearIcon from '@mui/icons-material/Clear';
import OutlinedInput from '@mui/material/OutlinedInput';

import FilterBarStyled from './styles';
import { COLOR_GREY_LIGHT } from '../../styles';

import { componentNames, TRACK_SINGLE_ITEM } from '../../analytics/constants';

const styles = {
  searchRoot: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box'
  },
  searchInput: {
    padding: 0,
    height: '100%'
  },
  clearIcon: {
    cursor: 'pointer',
    color: COLOR_GREY_LIGHT
  }
};

const FilterBar = ({
  isLoading,
  onTextChange,
  value,
  classes: {
    searchRoot,
    searchInput,
    clearIcon
  },
}) => {
  const { track } = useAnalytics();

  const inputRef = useRef();
  const timerIdRef = useRef();

  const handleSearchClear = useCallback(async() => {
    if (value) {
      onTextChange('');
    }
  }, [onTextChange, value]);

  const handleChange = useCallback((e) => {
    onTextChange(e.target.value);

    if(timerIdRef.current)
      clearTimeout(timerIdRef.current);

    timerIdRef.current = setTimeout(() =>
      track(TRACK_SINGLE_ITEM.search, {
        component: componentNames.ITEM_SEARCH,
        additional_info: {
          search_string: e.target.value
        }
      }), 1000);
  }, [onTextChange, track]);

  return (
    <FilterBarStyled>
      <OutlinedInput
        value={value}
        className="search-input"
        inputRef={inputRef}
        disabled={isLoading}
        placeholder="Search items"
        classes={{
          root: searchRoot,
          input: searchInput
        }}
        onChange={handleChange}
        endAdornment={
          <div className="actions">
            {value && <ClearIcon
              className={clearIcon}
              onClick={isLoading ?  undefined : handleSearchClear}
            />}
          </div>
        }
      />
    </FilterBarStyled>
  );
};

FilterBar.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  onTextChange: PropTypes.func.isRequired,
};

export default withStyles(styles)(FilterBar);
