import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import SvgIcon from '@mui/material/SvgIcon';

import ViewTooltip from './ViewTooltip/ViewTooltip';
import VideoHelperDialog from '../../../../components/VideoHelperDialog';

import { DASHBOARDS_WITH_TOOLTIP, icons } from './constants';
import { IconButtonStyled } from './styles';

const SingleView = ({ dashboardItem, disabled, onSwitchView, disabledText, active }) => {
  const [videoLink, setVideoLink] = useState(null);

  const handleClick = useCallback(() =>
    onSwitchView(dashboardItem.id),
  [onSwitchView, dashboardItem.id]
  );

  const IconComponent = useMemo(() => {
    return icons[dashboardItem.icon] || icons.TABLE;
  }, [dashboardItem]);

  const handleVideoDialogToggle = useCallback((e) => {
    e.stopPropagation();
    setVideoLink(state => !state ? DASHBOARDS_WITH_TOOLTIP[dashboardItem.icon]?.link : null);
  }, [dashboardItem.icon]);

  return (
    <>
      <ViewTooltip
        disabledText={disabled ? disabledText : null}
        dashboardItem={dashboardItem}
        onClick={handleVideoDialogToggle}
      >
        <span>
          <IconButtonStyled
            onClick={handleClick}
            disabled={disabled}
            sx={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
            disableRipple
            active={active}
          >
            <SvgIcon
              component={IconComponent}
              inheritViewBox
              sx={{
                fontSize: '14px',
                mr: '5px'
              }}
            />
          </IconButtonStyled>
        </span>
      </ViewTooltip>

      <VideoHelperDialog
        link={videoLink}
        onClose={handleVideoDialogToggle}
      />
    </>
  );
};

SingleView.propTypes = {
  dashboardItem: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  onSwitchView: PropTypes.func.isRequired,
  disabledText: PropTypes.string,
  active: PropTypes.bool,
};

export default SingleView;
