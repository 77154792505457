import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

import EditQuantityValue from './EditTextValue';
import ViewQuantityValue from './ViewTextValue';
import EditableValueContainer from '../../EditableValueContainer';

import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const TextValue = ({ data, itemId, tableParameterId, ...props }) => {
  const dispatch = useDispatch();

  const { text: textValue } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (newValue) => {
    const validValue = newValue || null;
    const valuesInput = { text: validValue };
    const variablesInput = {
      tableParameterId,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            tableValue: {
              __typename: 'TableValue',
              type: data.type,
              quantity: null,
              boolean: null,
              linkedTableItem: null,
              calculationError: null,
              ...variablesInput,
            },
            dependentTableValues: []
          }
        }
      });
    } catch (e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [dispatch, itemId, tableParameterId, updateFeatureValue, data]);

  return (
    <EditableValueContainer
      {...props}

      defaultValue={textValue}
      onUpdate={handleUpdate}
      viewComponent={ViewQuantityValue}
      editComponent={EditQuantityValue}
    />
  );
};

TextValue.propTypes = {
  itemId: PT.string.isRequired,
  tableParameterId: PT.string.isRequired,
  data: PT.shape({
    text: PT.string,
    type: PT.string
  })
};

export default TextValue;
