import React from 'react';
import PT from 'prop-types';
import { Box } from '@mui/material';

import RawText from '../RawText';

const booleanOptions = [
  { value: null, label: '' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const ViewBooleanValue = ({ data, onClick }) => (
  <Box
    onClick={onClick}
    width="100%"
    height="100%"
    display="flex"
    alignItems="center"
  >
    <RawText sx={{ textTransform: 'capitalize' }}>
      {booleanOptions.find(({ value }) => data === value).label}
    </RawText>
  </Box>
);

ViewBooleanValue.propTypes = {
  onClick: PT.func,
  data: PT.bool
};

export default ViewBooleanValue;
