import { gql } from '@apollo/client';
import { GET_NOTIFICATIONS_FRAGMENT } from '../../../../../components/Notifications/gql';

export const GET_UNREAD_NOTIFICATIONS_COUNT = gql`
  query UnreadNotifications {
    unreadNotifications
  }
`;

export const GET_NOTIFICATIONS = gql`
  ${GET_NOTIFICATIONS_FRAGMENT}
  query Notifications($first: Int, $after: String) {
    notifications(first: $first, after: $after) {
      edges {
        cursor
        ...NotificationListEdge
      }

      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation DeleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;

export const SET_READ_NOTIFICATION = gql`
  mutation SetReadNotification($id: ID!, $read: Boolean!) {
    setReadNotification(id: $id, read: $read) {
      id
    }
  }
`;

export const DELETE_ALL_NOTIFICATIONS = gql`
  mutation DeleteAllNotifications {
    deleteAllNotifications
  }
`;

export const SET_READ_ALL_NOTIFICATIONS = gql`
  mutation SetReadAllNotifications {
    setReadAllNotifications
  }
`;
