import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';

export const valueToText = (initData) => {
  if (!initData)
    return initData;

  const updateKey = (children) => {
    return children.map(child => {
      let data = {
        ...omit(child, '__typename'),
      };

      if(child.children){
        data = omit(data, 'text');
        data.children = updateKey(child.children);
      }

      // HACK: cleaning all non-null values from the state and
      // resetting to default when the result object is empty
      const nonNull = omitBy(data, isNull);

      return isEmpty(nonNull) ? { text: '' } : nonNull;
    });
  };

  return [{
    type: initData[0].type,
    children: updateKey(initData[0].children)
  }];
};

export const textToValue = (initData) => {
  const updateKey = (children, removeText) => {
    return children.map(child => {
      let data = {
        ...omit(omitBy(child, isNull), '__typename'),
      };

      if(removeText)
        data.text = '';

      if(child.children){
        data.children = updateKey(child.children, !!child.type);
      }

      return data;
    });
  };

  if (!initData)
    return {
      type: 'paragraph',
      children: { text: '' }
    };

  return {
    type: initData[0].type,
    children: updateKey(initData[0].children)
  };
};
