import React from 'react';
import PT from 'prop-types';

const Leaf = (props) => {
  if(props.leaf.color) {
    return (
      <span
        {...props.attributes}
        style={{ color: props.leaf.color }}
      >
        {props.children}
      </span>
    );
  }

  return (
    <span {...props.attributes}>{props.children}</span>
  );
};

Leaf.propTypes = {
  leaf: PT.object,
  attributes: PT.object,
  children: PT.object,
};

export default Leaf;
