import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Popover from '@mui/material/Popover';

import Avatar from '../../../Avatar';

import { COLOR_PRIMARY } from '../../../../styles';

const OwnerField = ({ name, userName, avatar, avatarColor }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleAvatarClick = useCallback(ev => {
    setAnchorEl(ev.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <React.Fragment>
      <Avatar
        name={name}
        src={avatar}
        size={40}
        onClick={handleAvatarClick}
        className="clickable"
        color={avatarColor ?? COLOR_PRIMARY}
      />
      <Popover
        className="owner-field-popover"
        key="popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="owner-field-popover-content">
          <div className="fullname">
            <span className="label">name:</span>
            <span className="value">{name}</span>
          </div>

          <div className="user-id">
            <span className="label">user:</span>
            <span className="value">{userName}</span>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
};

OwnerField.propTypes = {
  name: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  avatarColor: PropTypes.string
};

export default OwnerField;
