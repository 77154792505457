// Component names
export const componentNames = {
  ITEM_LINKAGE: 'item_linkage',
  ITEM_SEARCH: 'item_search',
  ITEM_ACTIONS: 'item_actions',
  COMMENTS: 'comments',
  PROFILE_IMAGE: 'profile_image',
  PERSONAL_INFO: 'personal_info',
  AFFILIATION_INFO: 'affiliation_info',
  RESEARCH_INFO: 'research_info',
  AUTHENTICATION: 'authentication',
  FORWARDED_MEASUREMENTS: 'forwarded_measurements',
  DATASET_SELECTION: 'dataset_selection',
  SIDEBAR: 'sidebar',
  SHARE_VIEW: 'share',
  DATA_VIEW: 'data_view',
  HELP: 'help',
  TABLE_STRUCTURE: 'table_structure',
};

export const sidebarSources = {
  TABLE_ITEM_NAME: 'table_item_name',
  TABLE_COLUMN_NAME_LINK: 'table_column_name_link',
  TABLE_VALUE_LINK: 'table_value_link',
  DASHBOARD_ITEM: 'dashboard_item',
  TABLE_COMMENTS: 'table_comments',
  TABLE_MEASUREMENTS: 'table_measurements',
};
// Action names

  // lists
const ADD = 'add';

  // others
const DELETE = 'delete';
const CLICK = 'click';
const SEARCH = 'search';
const CLONE = 'clone';
const LIKE = 'like';
const SHARE = 'share';
const CHANGE = 'change';
const UPDATE = 'update';
const OPEN = 'open';
const HOVER = 'hover';
const SAVED = 'saved';

const actionNames = {
  ITEM_LINKAGE: 'item_linkage',
  ITEM: 'item',
  COMMENT: 'comment',
  COMMENTS: 'comments',
  PROFILE_SETTINGS: 'profile_settings',
  NOTIFICATION_SETTINGS: 'notification_settings',
  TEAM_FILTER: 'team_filter',
  OWNER_FILTER: 'owner_filter',
  CREATION_DATE_FILTER: 'creation_date_filter',
  SLICE_BY_FILTER: 'slice_by_filter',
  SIDEBAR: 'sidebar',
  SHARE_VIEW: 'share',
  COPY_LINK: 'copy_link',
  VIEW_TYPE: 'view_type',
  SAVED_VIEW: 'saved_view',
  VIEW: 'view',
  HELP: 'help',
  HELP_VIDEO: 'help_video',
  CALCULATED_FIELDS: 'calculated_fields',
};

// Tracking actions
export const TRACK_ITEM_LINKAGE = {
  click: `${actionNames.ITEM_LINKAGE}__${CLICK}`,
};

export const TRACK_SINGLE_ITEM = {
  search: `${actionNames.ITEM}__${SEARCH}`,
  clone: `${actionNames.ITEM}__${CLONE}`,
};

export const TRACK_SINGLE_COMMENT = {
  add: `${actionNames.COMMENT}__${ADD}`,
  like: `${actionNames.COMMENT}__${LIKE}`,
  share: `${actionNames.COMMENT}__${SHARE}`,
  delete: `${actionNames.COMMENT}__${DELETE}`,
};

export const TRACK_PROFILE_SETTINGS = {
  change: `${actionNames.PROFILE_SETTINGS}__${CHANGE}`,
};

export const TRACK_NOTIFICATION_SETTINGS = {
  change: `${actionNames.NOTIFICATION_SETTINGS}__${CHANGE}`,
};

export const TRACK_TEAM_FILTER = {
  update: `${actionNames.TEAM_FILTER}__${UPDATE}`
};

export const TRACK_OWNER_FILTER = {
  update: `${actionNames.OWNER_FILTER}__${UPDATE}`
};

export const TRACK_CREATION_DATE_FILTER = {
  update: `${actionNames.CREATION_DATE_FILTER}__${UPDATE}`
};

export const TRACK_SLICE_BY_FILTER = {
  update: `${actionNames.SLICE_BY_FILTER}__${UPDATE}`
};

export const TRACK_SIDEBAR = {
  open: `${actionNames.SIDEBAR}__${OPEN}`
};

export const TRACK_SHARE_VIEW = {
  share: actionNames.SHARE_VIEW,
  copyLink: actionNames.COPY_LINK
};

export const TRACK_VIEW_TYPE = {
  change: `${actionNames.VIEW_TYPE}__${CHANGE}`
};

export const TRACK_SAVED_VIEW = {
  change: `${actionNames.SAVED_VIEW}__${CHANGE}`,
  save: `${actionNames.VIEW}__${SAVED}`,
};

export const TRACK_HELP_VIDEO = {
  hover: `${actionNames.HELP}__${HOVER}`,
  open: `${actionNames.HELP_VIDEO}__${OPEN}`,
};

export const TRACK_CALCULATED_FIELDS = {
  update: `${actionNames.CALCULATED_FIELDS}__${UPDATE}`
};
