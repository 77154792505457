import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import PT from 'prop-types';

import EditBooleanValue from './EditBooleanValue';
import ViewBooleanValue from './ViewBooleanValue';
import EditableValueContainer from '../../EditableValueContainer';

import { UPDATE_FEATURE_VALUE } from '../gql';
import { openAppSnackbarNotification } from '../../../../../../services/snackbar-notifications/actions';

const BooleanValue = ({ data, itemId, tableParameterId, readOnly, ...props }) => {
  const dispatch = useDispatch();

  const {
    boolean,
  } = data;

  const [updateFeatureValue] = useMutation(UPDATE_FEATURE_VALUE);

  const handleUpdate = useCallback(async (newValue) => {
    const valuesInput = { boolean: newValue };
    const variablesInput = {
      tableParameterId,
      tableItemId: itemId,
      ...valuesInput
    };

    try {
      await updateFeatureValue({
        variables: { input: variablesInput },
        optimisticResponse: {
          updateTableValue: {
            tableValue: {
              __typename: 'TableValue',
              type: data.type,
              quantity: null,
              text: null,
              linkedTableItem: null,
              calculationError: null,
              ...variablesInput,
            },
            dependentTableValues: []
          }
        }
      });
    } catch (e) {
      console.error(e);

      dispatch(
        openAppSnackbarNotification({
          variant: 'ERROR',
          message: e.message
        })
      );
    }
  }, [dispatch, itemId, tableParameterId, updateFeatureValue, data]);

  return (
    <EditableValueContainer
      {...props}

      readOnly={readOnly}
      defaultValue={boolean}
      onUpdate={handleUpdate}
      viewComponent={ViewBooleanValue}
      editComponent={EditBooleanValue}
    />
  );
};

BooleanValue.propTypes = {
  readOnly: PT.any,
  itemId: PT.string.isRequired,
  tableParameterId: PT.string.isRequired,
  data: PT.shape({
    boolean: PT.bool,
    type: PT.string
  }).isRequired
};

export default BooleanValue;
