import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import MenuIcon from '@mui/icons-material/Menu';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { ItemActionsMenu } from './styles';

const ItemActions = ({ item, onRestore }) => {
  const { id } = item;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleToggleMenu = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleRestore = useCallback(() => {
    onRestore(id);
    handleClose();
  }, [onRestore, id, handleClose]);

  const elementId = `simple-menu-${id}`;

  return (
    <>
      <div
        className="action-container"
      >
        <Button
          aria-owns={anchorEl ? elementId : null}
          aria-haspopup="true"
          onClick={handleToggleMenu}
        >
          <MenuIcon />
        </Button>

        <ItemActionsMenu
          id={elementId}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleRestore}>
            <UnarchiveIcon />
            Restore from Deleted
          </MenuItem>
        </ItemActionsMenu>
      </div>
    </>
  );
};

ItemActions.propTypes = {
  item: PropTypes.object.isRequired,
  onRestore: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

export default ItemActions;
