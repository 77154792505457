import { Transforms } from 'slate';

export default function withColumns(editor) {
  const { isInline, isVoid } = editor;

  editor.isInline = element => {
    return element.type === 'column' ? true : isInline(element);
  };

  editor.isVoid = element => {
    return element.type === 'column' ? true : isVoid(element);
  };

  editor.insertColumn = ({ id, title }) => {
    const column = {
      id,
      type: 'column',
      children: [{ text: title }],
    };

    Transforms.insertNodes(editor, column);
    setTimeout(() => { Transforms.move(editor); }, 0);
  };

  return editor;
}
