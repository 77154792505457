import React, { useCallback, useRef, useState } from 'react';
import { useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';

import { UNITS } from './gql';
import PT from 'prop-types';

const styles = {
  unitsBlock: {
    border: '1px solid #DCDBDC',
    borderRadius: '0 0 4px 4px',
    borderTop: 'none',
    p: '4px 6px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '.MuiTypography-root': {
      fontSize: '14px',
      color: 'rgba(0, 0, 0, 0.6)'
    },
    '.MuiButtonBase-root': {
      width: '24px',
      height: '24px',
    }
  },
  popper: {
    zIndex: 1300
  },
  menu: {
    height: '150px',
    overflowY: 'auto',
  },
};

const UnitsBlock = ({ setUnit, unit }) => {
  const { data: unitsData } = useQuery(UNITS);

  const [openUnits, setOpenUnits] = useState(false);

  const anchorRef = useRef();

  const toggleUnitMenu = useCallback(() => {
    setOpenUnits(state => !state);
  }, []);

  const selectUnit = useCallback((value) => {
    setUnit(value);
    toggleUnitMenu();
  }, [setUnit, toggleUnitMenu]);

  return (
    <>
      <FormControl
        fullWidth
        sx={styles.unitsBlock}
      >
        <Typography>
          Units: {unit.name}
        </Typography>

        <IconButton
          ref={anchorRef}
          onClick={toggleUnitMenu}
          disableRipple
        >
          {!openUnits ?
            <KeyboardArrowDownIcon fontSize="small" /> :
            <KeyboardArrowUpIcon fontSize="small" />
          }
        </IconButton>
      </FormControl>

      <Popper
        open={openUnits}
        anchorEl={anchorRef.current}
        role={undefined}
        sx={styles.popper}
        placement="bottom-start"
      >
        <Paper
          sx={styles.menu}
          elevation={2}
        >
          <ClickAwayListener onClickAway={toggleUnitMenu}>
            <MenuList>
              <MenuItem
                selected={unit.id === null}
                onClick={selectUnit.bind(null, { id: null, name: 'None' })}
              >
                None
              </MenuItem>
              {unitsData?.units.map((option, index) => (
                <MenuItem
                  key={option.id}
                  selected={index === unit.id}
                  onClick={selectUnit.bind(null, option)}
                >
                  {option.name} {option.description ? `(${option.description})` : ''}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

UnitsBlock.propTypes = {
  unit: PT.object,
  setUnit: PT.func.isRequired,
};

export default UnitsBlock;
