import styled from 'styled-components';

export const Container = styled('div')`
  max-width: 100%;
`;

export const TopBlock = styled('div')({
  '& .MuiTypography-root': {
    display: 'inline-block',
    verticalAlign: 'bottom',
    lineHeight: 'normal',
  }
});
