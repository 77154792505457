import styled from 'styled-components';

import { TOOLBAR_HEIGHT_PX } from '../constants';
import { HEADER_BREAKPOINT } from '../../../styles';

export const ToolbarContainer = styled.div`
  min-height: ${TOOLBAR_HEIGHT_PX}px;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, .12);

  display: flex;
  align-items: center;
  gap: 13px;

  position: sticky;
  left: 0px;
  top: 0px;
  z-index: 3;

  @media (${HEADER_BREAKPOINT}) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 17px;
    padding-bottom: 10px;
  }
`;

export const ToolbarHeaderActionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  gap: 14px;
  flex-shrink: 0;
  
  & > div {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  @media (${HEADER_BREAKPOINT}) {
    flex-direction: column;
    margin-left: unset;
  }
`;
