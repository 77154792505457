import { gql } from '@apollo/client';

export const GET_VIEWER = gql`
  query ViewerBasicSessionProfile {
    viewer {
      id
      userId
      name
      firstName
      lastName
      perovskiteUser

      organization {
        id
        title
      }

      idleSignOut
    }
  }
`;
