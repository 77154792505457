import React, { useCallback, useState } from 'react';
import PT from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import FormControl from '@mui/material/FormControl';

import ArrowIcon from '@mui/icons-material/ArrowDropDown';

import EditButtons from '../../EditButtons';
import { BOOLEAN_VALUE_TYPE } from './constants';
import { interceptKeyboardEvent } from './utils';

import { Container } from './styles';

const options = [
  { value: null, label: 'Empty' },
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const styles = {
  form: {
    '&:hover': {
      backgroundColor: 'transparent'
    },
    flex: '1 0 70px',
    width: '100%',
    maxWidth: '100%',
    '& .boolean-btn-label': {
      transform: 'translate(0px, -4px) scale(0.75)'
    },

    '& .boolean-btn': {
      height: '30px',
      width: '100%',
      borderRadius: '4px',
      borderColor: 'rgba(0, 0, 0, 0.23)',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: '8px',
      textTransform: 'none',
    }
  },
  booleanMenu: {
    '& .menu-items': {
      outline: 'none',
      '& li': {
        display: 'flex',
        alignItems: 'center',
        width: '100%'
      }
    },
    '& ul': {
      padding: 0
    }
  }
};

const useStyles = makeStyles(styles);

const BooleanEditValue = ({
  value,
  onSubmit,
  onCancel,
  onChange
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = useCallback((ev) => setAnchorEl(ev.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);
  const handleSelect = useCallback((selectedValue) => {
    onChange(selectedValue);
    handleClose();
  }, [onChange, handleClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(BOOLEAN_VALUE_TYPE, value);
  }, [onSubmit, value]);


  const handleKeyDown = useCallback(event => {
    interceptKeyboardEvent({
      event,
      onEnter() {
        onSubmit(BOOLEAN_VALUE_TYPE, value);
      },
      onEscape() {
        onCancel();
      }
    });
  }, [value, onSubmit, onCancel]);

  const label = options.find(i => i.value === value).label;

  return (
    <Container onKeyDown={handleKeyDown}>
      <Box width="100%">
        <FormControl
          classes={{ root: classes.form }}
          aria-owns="boolean-menu"
          aria-haspopup="true"
          onClick={openMenu}
        >
          <Button
            className="boolean-btn"
            variant="outlined"
            color="inherit"
            disableRipple
          >
            <Typography variant="body2">
              {label}
            </Typography>

            <ArrowIcon size="small" />
          </Button>
        </FormControl>

        <Menu
          id="boolean-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.booleanMenu}
          slotProps={{
            paper: {
              style: {
                width: '256px',
              },
            }
          }}
        >
          <div className="menu-items">
            {options.map(i => (
              <MenuItem
                onClick={() => handleSelect(i.value)}
                key={i.value}
                selected={value === i.value}
                onKeyDown={e => {
                  if (e.code === 'Enter') {
                    e.preventDefault();
                  }
                }}
              >
                <Typography variant="body2">
                  {i.label}
                </Typography>
              </MenuItem>
            ))}
          </div>
        </Menu>
      </Box>

      <EditButtons
        onCancel={onCancel}
        onSubmit={handleSubmit}
      />
    </Container>
  );
};

BooleanEditValue.propTypes = {
  value: PT.bool,
  onSubmit: PT.func.isRequired,
  onCancel: PT.func.isRequired,
  onChange: PT.func.isRequired
};

export default BooleanEditValue;
