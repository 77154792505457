import React from 'react';
import dateFnsFormat from 'date-fns/format';
import PropTypes from 'prop-types';

import OwnerField from './cells/OwnerField';
import ItemTitle from './cells/ItemTitle';
import DeletedDate from './cells/DeletedDate';
import ItemType from './cells/ItemType';
import ItemLocation from './cells/ItemLocation';

const TableRowContent = ({
  type,
  data,
  renderItemActions
}) => {
  switch (type) {
    case 'checkbox':
      return true;

    case 'id':
      return data.id;

    case 'sample-name':
      return (
        <ItemTitle
          id={data.id}
          title={data.title}
        />
      );

    case 'trashedTimestamp':
      return (
        <DeletedDate
          id={data.id}
          timestamp={data.trashedTimestamp}
        />
      );

    case 'type':
      return (
        <ItemType
          id={data.id}
          type={data.type}
        />
      );

    case 'path':
      return (
        <ItemLocation
          id={data.id}
          location={data.path}
        />
      );

    case 'creator':
      if(!data.creator)
        return;

      return (
        <OwnerField
          userName={data.creator.userName}
          name={data.creator.name}
          avatar={data.creator.pictureDownloadURL}
          avatarColor={data.creator.avatarColor}
        />
      );

    case 'date-updated':
      return dateFnsFormat(data.dateUpdated || Date.now(), 'dd/MM/yyyy HH:mm');

    case 'date-created':
      return dateFnsFormat(data.dateCreated || Date.now(), 'dd/MM/yyyy HH:mm');

    case 'action':
      return renderItemActions(data);
    case 'children':
      return true;
  }
};

TableRowContent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.object,
  renderItemActions: PropTypes.func,
};

export default TableRowContent;
